<script>
    import { onMount } from "svelte";
    import Carousel from "svelte-carousel";

    const galleryId = window.location.pathname.split("/gallery/")[1];
    let gallery = [{ id: 0, title: "", images: [{ src: "", alt: "" }] }];
    let showGallery = false;

    onMount(async () => {
        const res = (await fetch("/gallery-api/gallery?id=" + galleryId)) || [
            { id: 0, title: "", images: [{ src: "", alt: "" }] },
        ];
        gallery = await res.json();
        gallery = gallery[0];
        console.log("Gallery", gallery);
    });

    const pageBack = () => {
        history.back();
    };

    const toagleGallery = ()=>{
        showGallery = !showGallery
    }

</script>

{#if showGallery}
    <div class="bg-black px-0 text-light vh-100">
        <button class="btn btn-lg text-white" on:click={toagleGallery}
            ><i class="bi bi-x-circle h1" /></button
        >
        <div class="row justify-content-center">
            <div class="col-auto">
                <h1>{gallery.title}</h1>
            </div>
        </div>
        {#if gallery.images}
            <div
                class="row justify-content-center align-items-center vh-100"
            >
                <div class="col">
                    <Carousel 
                        let:loaded
                        let:showPrevPage
                        let:showNextPage
                    >
                        <div style="min-width: 4rem;" slot="prev" on:click={showPrevPage} role="button" tabindex="0">
                            <div class="row justify-content-center align-items-center h-100">
                                <div class="col-auto">
                                    <i class="bi bi-arrow-left-circle-fill h1 text-white" />
                                </div>
                            </div>
                          </div>

                          <div style="min-width: 4rem;" slot="next" on:click={showNextPage} role="button" tabindex="0">
                            <div class="row justify-content-center align-items-center h-100">
                                <div class="col-auto">
                                    <i class="bi bi-arrow-right-circle-fill h1 text-white" />
                                </div>
                            </div>
                          </div>

                        {#each gallery.images as image, imageIndex (image)}
                            <div class="img-container">
                                {#if loaded.includes(imageIndex)}
                                    <div class="row justify-content-center">
                                        <div class="col-auto">
                                            <img
                                                class="img-fluid"
                                                style="max-width: 100%; height: auto;"
                                                src={image.src}
                                                alt={image.alt}
                                            />
                                            <p class="text-center">{image.alt}</p>
                                        </div>
                                    </div>
                                {/if}
                            </div>
                        {/each}
                    </Carousel>
                </div>
            </div>
        {/if}
    </div>
{:else}
<div class="row">
    <div class="col-auto">
        <button class="btn btn-lg" on:click={pageBack}>
            <i class="bi bi-arrow-left-circle h1" />
        </button>
    </div>
</div>

<div class="row justify-content-center mb-5">
    <div class="col-auto">
        <h1>{gallery.title}</h1>
    </div>
</div>

<div class="row justify-content-center">
    {#if gallery.images}
    {#each gallery.images as image, imageIndex (image)}
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div class="col-auto" on:click={toagleGallery} >
        <img
            class="img-fluid img-thumbnail"
            style=" height: 10rem;"
            src={image.src}
            alt={image.alt}
        />
        <p class="text-center">{image.alt}</p>
    </div>
    {/each}
    {/if}
</div>

{/if}



<style>
    @import "/css/font.css";
    @import "/css/bs-custom.css";
    @import "/css/bootstrap-icons.css";
</style>
