<script>
	import { onMount } from "svelte";
	import router from "page";
	var disabled = true;
	var showProgress = false;
	var loged = false;
	var formVal = {
		email: "",
		password: "",
		remember: false,
        email_valid: false,
        password_valid: false,
	};

	var email = "";

	$: email = getCookie("email");

	onMount(() => {
		let token = getCookie("token");
  		if (token != "") {
			formVal.email = "Prihlasené";
			loged = true;
 		}
	});

    function emailValidator (value) {
            formVal.email_valid = (value && !!value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) || false
            return (value && !!value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) || false
        }

    function passwordValidator (value) {
            formVal.password_valid = (value && !!value.match(/[a-zA-Z0-9$&+,:=?@#'.^*%!]{7,20}$/)) || false
            return (value && !!value.match(/[a-zA-Z0-9$&+,:=?@#'.^*%!]{7,20}$/)) || false
        }


	$: disabled = formVal.email_valid && formVal.password_valid


	const setCookie = (cname,cvalue,exdays) => {
  		const d = new Date();
  		d.setTime(d.getTime() + (exdays*24*60*60*1000));
  		let expires = "expires=" + d.toUTCString();
  		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}

	const getProfile = async (token,exdays)=>{
		const options = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"token": token
			},
		};
		let res = await fetch(`/customer-api/profile`, options)
			.then((res) => res.json())
			.then((data) => {
				setCookie("email", data.email, exdays);
				email = data.email
			})
			.catch((err) => {
				console.log("getProfile: Email not set", err);
			});
	}

	const signOut = () => {
  		let expires = "expires=Thu, 01 Jan 1970 00:00:00 UTC";
  		document.cookie = "token=;" + expires + ";path=/";
		document.cookie = "email=;" + expires + ";path=/";
	}

	const getCookie = (cname) => {
  		let name = cname + "=";
  		let decodedCookie = decodeURIComponent(document.cookie);
  		let ca = decodedCookie.split(';');
  		for(let i = 0; i <ca.length; i++) {
    		let c = ca[i];
    		while (c.charAt(0) == ' ') {
      			c = c.substring(1);
    		}
    		if (c.indexOf(name) == 0) {
      			return c.substring(name.length, c.length);
    		}
  		}
  		return "";
	}

	const requestAPI = async () => {
		var user = formVal;
		showProgress = true;
		const options = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"Authorization": window.btoa(`${user.email}:${user.password}`)
			},
		};
		try {
			let res = await fetch(`/customer-api/auth`, options)
				.then((res) => res.json())
				.then((data) => {
					showProgress = false;
					if (data.message == 'unautorized'){
						alert("Unauthorized");
					}else{
						var exdays = 1;
					if (formVal.remember) {
						exdays = 365;
					}
					setCookie("token", data.token, exdays);
					getProfile(data.token, exdays);
					loged = true;
					router.redirect("/")
					}

				})
				.catch((err) => {
					showProgress = false;
					alert("Unauthorized", err);
				});
		} catch (err) {
			showProgress = false;
			console.log(err);
		}
	};
</script>

<main class="text-center align-middle">
	{#if loged}
	<div style="width: 28rem; height: 20rem;">
		<div>
			<i class="bi bi-person-fill" style="font-size: 3rem; color: gray;"></i>
			<h5>
				{email}
			</h5>
			<a href="/" class="btn btn-primary" on:click={signOut} role="button">Odhlásiť</a>
		</div>
	</div>
	{/if}
	{#if !loged}
	<form style="width: 28rem; height: 20rem;">
		<div>
			<h2 class="mt-1">Prihlásenie</h2>
		</div>
		<div class="mt-1">
			Nemáte ešte účet? <span><a href="/registracia">Registácia</a></span>
		</div>

		<div class="form-floating m-3 mx-5">
			<input
				bind:value={formVal.email}
				on:change={emailValidator(formVal.email)}
				type="email"
				class="form-control"
				id="floatingInput"
				placeholder="name@example.com"
			/>
			<label for="floatingInput">Emailová adresa</label>
		</div>
		<div class="form-floating  m-3 mx-5">
			<input
				bind:value={formVal.password}
				on:change={passwordValidator(formVal.password)}
				type="password"
				class="form-control"
				id="floatingPassword"
				placeholder="Password"
			/>
			<label for="floatingPassword">Heslo</label>
		</div>

		<div class="row m-3 mx-5">
			<div class="col">
				<div class="form-check">
					<input
						bind:checked={formVal.remember}
						class="form-check-input"
						type="checkbox"
						value=""
						id="rememberme"
					/>
					<label class="form-check-label" for="rememberme">
						Zapamätaj si ma
					</label>
				</div>
			</div>
			<div class="col">
				<span class="ml-3"
					><a href="/zabudnute-heslo">Zabudnuté heslo</a></span
				>
			</div>
		</div>

		<div class="my-4 mx-5 d-grid d-md-block">
			<button disabled={!disabled} class="btn-lg btn-primary" on:click|preventDefault={requestAPI}>
				{#if showProgress}
				<span  class="spinner-border spinner-border-sm" role="status" />
				{/if}
				Prihlásiť sa</button
			>
		</div>
	</form>
	{/if}
</main>

<style>
	@import "/css/font.css";
	@import "/css/bs-custom.css";
	@import "/css/bootstrap-icons.css";
</style>
