<script>
  import { onMount } from "svelte";
  import { onDestroy } from "svelte";
  import router from "page";
  import { order } from "./storage/orderStore.js";
  import { default as QrCode } from "qrious";

  const QRcode = new QrCode();

  // QR VARS
  let errorCorrection = "L";
  let background = "#fff";
  let color = "#000";
  let size = "450";
  let padding = 30;
  let image = "";
  let shippingPrice = 0;
  let isCalculate = false;

  let selectedShipping = "kurier";


  let selectedPayment = "prevod";
  let orderStore;

  order.subscribe((orderValue) => {
    orderStore = orderValue;
  });

  const unsubscribe = order.subscribe((orderValue) => {
    orderStore = orderValue;
  });

  onDestroy(unsubscribe);

  onMount(async () => {

    //clear order id
    orderStore.id = 0;
    orderStore.token = getCookie("token");

    console.log("SHIPPING ORDER onMount: ", orderStore);
    const options = {
      method: "POST",
      body: JSON.stringify(orderStore),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    await fetch("/order-api/order/price/calculate", options)
      .then((res) => res.json())
      .then(async (data) => {
        orderStore = data;
        let shipping = await data.products.filter(
          (product) => product.name == "Doprava"
        );

        console.log(shipping,'shipping price')

        if (shipping.length > 0) {
          shippingPrice = shipping[0].price;
        }

        if(orderStore.pkType == "IC"){
          orderStore.products = orderStore.products.filter((product) => product.name != "Doprava");
          orderStore.sum = await recalculateSum(orderStore.products);
        }

        isCalculate = true;
      })
      .catch((err) => {
        console.log("Not calculated: ", err);
      });

    console.log("ORDER: ", orderStore);
    //await emptyShippment();
    //generateQrCode();
    backToTop();
  });

  const setDefaultShippingOrPayment = () => {
    // Check if selectedShipping is no longer available
    // if (!orderStore.products.some(product => product.name === selectedShipping)) {
    //   selectedShipping = "kurier"; // Reset to default option
    // }

    // Check if selectedPayment is no longer available
    if (selectedShipping === "kurier" && !["prevod", "dobierka"].includes(selectedPayment)) {
      selectedPayment = "prevod"; // Reset to default option for kurier
    } else if (selectedShipping === "without" && !["prevod", "napobocke"].includes(selectedPayment)) {
      selectedPayment = "prevod"; // Reset to default option for without
    }
  };

  const onSelectedShipping = () => {

    orderStore.selectedShipping = selectedShipping;

    setDefaultShippingOrPayment();
    console.log("SHIPPING ORDER on selected shipping: ", orderStore);
  }

  const onSelectedPayment = async () => {

    orderStore.selectedPayment = selectedPayment;

    console.log("SHIPPING ORDER on selected payment: ", orderStore);

    const orderStoreProductsWithoutDoprava = orderStore.products.filter(
      (product) => product.name != "Doprava"
    );

    orderStore.sum = await recalculateSum(orderStoreProductsWithoutDoprava);
    orderStore.products = orderStoreProductsWithoutDoprava;

    const options = {
      method: "POST",
      body: JSON.stringify(orderStore),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    await fetch("/order-api/order/price/calculate", options)
      .then((res) => res.json())
      .then(async (data) => {
        orderStore = data;
        let shipping = await data.products.filter(
          (product) => product.name == "Doprava"
        );

        console.log(shipping,'shipping price')

        if (shipping.length > 0) {
          shippingPrice = shipping[0].price;
        }

        if(orderStore.pkType == "IC"){
          orderStore.products = orderStore.products.filter((product) => product.name != "Doprava");
          orderStore.sum = await recalculateSum(orderStore.products);
        }

        isCalculate = true;
      })
      .catch((err) => {
        console.log("Not calculated: ", err);
      });

    console.log("ORDER: ", orderStore);
  }

  const emptyBasket = () => {
    order.update((order) => {
      let newOrder = order;
      newOrder.products = [];
      newOrder.sum = 0;
      return newOrder;
    });
  };

  const removeProduct = (index) => {
    order.update((order) => {
      let newOrder = order;
      newOrder.products.splice(index, 1);
      newOrder.sum = 0;
      newOrder.products.forEach((product) => {
        newOrder.sum = newOrder.sum + product.price * product.quantity;
      });
      return newOrder;
    });
  };

  const getEuro = (num) => {
    const euros = new Intl.NumberFormat(`sk-SK`, {
      currency: `EUR`,
      style: "currency",
    }).format(num);
    return euros;
  };

  const recalculateSum = async (products) => {
    var sum = 0;
    await products.forEach((product) => {
      let price = 0;
      if (product.action) {
        price = Number(product.actionprice);
      } else {
        price = Number(product.price);
      }
      sum = sum + price * product.quantity;
    });
    return sum;
  };

  const emptyShippment = async () => {
    orderStore.products = orderStore.products.filter(
      (product) => product.name != "Doprava"
    );
    orderStore.sum = await recalculateSum(orderStore.products);
  };

  const removeShipping = async (shipping) => {
    if (shipping == "without") {
      orderStore.products = orderStore.products.filter(
        (product) => product.name != "Doprava"
      );
    }
    orderStore.sum = await recalculateSum(orderStore.products);
    console.log("SHIPPING: ", shipping, "PRODUCTS: ", orderStore);
  };

  const backToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  const generateQrCode = async () => {
    QRcode.set({
      background,
      foreground: color,
      level: errorCorrection,
      padding,
      size,
      value: orderStore.qrstring,
    });

    image = QRcode.toDataURL("image/jpeg");
    orderStore.qrimage = image;
    console.log(orderStore);
  };

  const sendOrder = async () => {
    if(selectedShipping == "without"){await removeShipping("without")}
    let token = getCookie("token");
    let state = "ordered";
    //console.log(orderStore);
    //router.redirect("/checkout/payment");
    const options = {
      method: "PATCH",
      body: JSON.stringify({
        "products": orderStore.products,
        "state":state,
        "token":token,
        "sum":orderStore.sum,
        "vs":orderStore.vs,
        "email":orderStore.email,
        "pkType": orderStore.pkType,
        "qrstring":orderStore.qrstring,
        "qrimage":orderStore.qrimage,
        "selectedPayment": selectedPayment, 
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      let res = await fetch(`/order-api/order/` + orderStore.id, options)
        .then((res) => res.json())
        .then(async (data) => {
          orderStore = data;
          console.log("UpdateOrder ret: ", orderStore);
          //await generateQrCode();
          order.set(orderStore);
          router.redirect("/checkout/payment");
        })
        .catch((err) => {
          alert("Not sent", err);
        });
    } catch (err) {
      console.log(err);
    }
  };
</script>

<main class="bg-light">
  <div class="container bg-body">
    <h3 class="mt-3">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="/">
              <i class="bi bi-house-fill" />
            </a>
          </li>
          <li class="breadcrumb-item">
            <a href="/checkout/information">Dodacie údaje</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            <a href="/checkout/shipping">Doprava a platba</a>
          </li>
          <li class="breadcrumb-item">Potvrdenie objednávky</li>
        </ol>
      </nav>
    </h3>
    <!-- {#if orderStore.pkType == "EP"}
          <h1>Typ balenia: Europaleta</h1>
          {/if}
          {#if orderStore.pkType == "BL"}
          <h1>Typ balenia: Balík</h1>
          {/if}           -->

    <div class="row">
      <div class="col-md-8 bg-light text-body">
        {#if $order.products.length == 0}
        <div class="container text-center mt-5 mb-5">
            <h3>Váš košík je prázdny.</h3>
            <p>Vyberte si z našej ponuky</p>
            <a href="/" class="btn btn-primary">Pozrieť ponuku</a>
         </div>
             
        {:else}
        <div class="row align-items-center m-3 p-2">
          <div class="col-md">
            <h3 class="m-0 p-0">Zhrnutie objednávky</h3>
          </div>
          <div class="col-md" />
        </div>
        {#each $order.products as product, index}
          <div
            class="row align-items-center text-center text-md-start  shadow m-3 p-2"
          >
            <div class="col-md">
              <img
                style="height: 10rem;"
                class="img-thumbnail"
                src={product.images[0]}
                alt={product.name}
              />
            </div>
            <div class="col-md">
              <div class="row">
                <div class="col">
                  <p class="mb-1">Cena s DPH</p>
                  <h3 class="">{getEuro(product.actionprice || product.price)}</h3>
                </div>
                <div class="col">
                  <p class="mb-1">Počet</p>
                  <h3 class="">{product.quantity}</h3>
                </div>
              </div>
            </div>
            <div class="col-md " />
          </div>
        {/each}
        {#if selectedShipping == "kurier"}
        <div class="row m-3">
          <div class="col-md-3 col-6">
            <h3>Doprava:</h3>
          </div>
          <div class="col">
            <h3>
              {#if shippingPrice > 0}
                {getEuro(shippingPrice)}
              {:else}
                Cena dopravy na opýtanie
              {/if}
            </h3>
          </div>
        </div>
        {/if}

        <div class="row m-3">
          <hr />
        </div>
        <div class="row m-3">
          <div class="col-md-3 col-6" >
            <h3>Spolu:</h3>
          </div>
          <div class="col">
          
            {#if selectedShipping == "kurier"}
            <h3>
              {#if shippingPrice > 0}
              {getEuro($order.sum + shippingPrice)}
              {:else}
              {getEuro($order.sum)}
              {/if}
            </h3>
            {:else}
            <h3>
              {getEuro($order.sum)}
            </h3>
            {/if}

          </div>
        </div>
        {/if}
      </div>


      <div class="col-md-4 p-md-3 bg-dark text-light">
        <h1>Spôsob dopravy:</h1>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            bind:group={selectedShipping}
            name="selectedShipping"
            value={"kurier"}
            id="selectedShippingKurier"
            on:change={onSelectedShipping}
          />
          <label class="form-check-label" for="selectedShippingKurier">
            Doručenie kurierom:
            {#if !isCalculate}
            <div class="d-flex align-items-center">
              <strong>Vypocitavam cenu...</strong>
              <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
            </div>
            {/if}
            {#if shippingPrice > 0}
              {getEuro(shippingPrice)}
            {:else}
              Cena dopravy na opýtanie
            {/if}
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            bind:group={selectedShipping}
            name="selectedShipping"
            value={"without"}
            id="selectedShippingWihout"
            on:change={onSelectedShipping}
          />
          <label class="form-check-label" for="selectedShippingWihout">
            Osobne prevzatie na pobočke: zdarma
          </label>
        </div>
        <h1 class="mt-3">Spôsob platby:</h1>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            bind:group={selectedPayment}
            name="selectedPayment"
            value={"prevod"}
            id="selectedPayment"
            on:change={onSelectedPayment}
          />
          <label class="form-check-label" for="selectedPayment">
            Bankovým prevodom /QR kod/
          </label>
        </div>
        {#if selectedShipping === 'kurier'}
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              bind:group={selectedPayment}
              name="selectedPayment"
              value={"dobierka"}
              id="selectedPayment"
              on:change={onSelectedPayment}
            />
            <label class="form-check-label" for="selectedPayment">
              Na dobierku
            </label>
          </div>
        {/if}
        {#if selectedShipping === 'without'}
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              bind:group={selectedPayment}
              name="selectedPayment"
              value={"napobocke"}
              id="selectedPayment"
              on:change={onSelectedPayment}
            />
            <label class="form-check-label" for="selectedPayment">
              Karta/Hotovosť na pobočke
            </label>
          </div>
        {/if}
        <!-- {#if orderStore.pkType == "IC"}
          <h3>
            Pre nacenenie dopravy nás kontaktujte telefonicky alebo emailom
          </h3>
        {/if} -->
        <!-- {#if shippingPrice > 0} -->
          <div class="mt-3" style="padding-bottom: 30rem;">
            <a
              href="/checkout/payment"
              on:click|preventDefault={sendOrder}
              class="btn btn-primary btn-lg">Potvrdiť objednávku</a
            >
            <p>s povinnosťou platby</p>
            <p>
              Stlačením súhlasíte s
              <a
                href="http://eshop.bugarsro.sk/pages/vsetko-o-nakupe/podmienky/obchodne-podmienky"
                target="_blank">obchodnými podmienkami</a
              >
            </p>
          </div>
        <!-- {:else}
          <div class="mt-3" style="padding-bottom: 30rem;">
            <a
              href="/checkout/payment"
              class="btn btn-primary btn-lg disabled"
              tabindex="-1"
              role="button"
              aria-disabled="true">Potvrdiť objednávku</a
            >

            <p>s povinnosťou platby</p>
            <p>
              Stlačením súhlasíte s
              <a
                href="http://eshop.bugarsro.sk/pages/vsetko-o-nakupe/podmienky/obchodne-podmienky"
                target="_blank">obchodnými podmienkami</a
              >
            </p>
          </div>
        {/if} -->
      </div>
    </div>
  </div>
</main>

<style>
  .container {
    max-width: 1680px !important;
  }
  @import "/css/font.css";
  @import "/css/bs-custom.css";
  @import "/css/bootstrap-icons.css";
</style>
