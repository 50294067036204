<script>
	import router from "page";
	import { onMount } from "svelte";
	import { each } from "svelte/internal";
	import { order } from "./storage/orderStore.js";
	import { search } from "./storage/searchStore.js";
	import Viewport from "svelte-viewport-info";

	let isLoged = false;
	let token = "";
	let email = "";
	let searchString = "";

	$: isLoged = getCookie("token") != "";

	let pages = [];
	let topBanner = [];
	let firstSelected = undefined;
	let firstLevelRoutes = [];
	let secondLevelRoutes = [];
	let thirdLevelRoutes = [];
	let showSecondLevel = false;
	let pathname = window.location.pathname || "";
	let mobileMenu = false;

	onMount(async () => {
		const resTopBanner = await fetch("/pages-api/topBanner");
		topBanner = await resTopBanner.json();
		const res = await fetch("/pages-api/pages");
		pages = await res.json();
		pages = await pages.filter((page) => page.menu == "horné");
		pages = await pages.sort((a, b) =>
			Number(a.order) > Number(b.order) ? 1 : -1
		);
		firstLevelRoutes = await firstLevel();

		// token = getCookie("token");
		// if (token != "") {
		// 	//formVal.email = "Prihlasené";
		// 	isLoged = true;
		// 	isLoged = isLoged;
		// }
		email = getCookie("email");
		console.log(pathname.split("/")[1]);
		setMenu();
	});

	const setMenu = () => {
		if (Viewport.Width < 768) {
			mobileMenu = true;
		} else {
			mobileMenu = false;
		}
	};

	const isActiveMenu = (page) => {
		let ret = false;
		if (pathname.split("/")[1] == page) {
			ret = true;
		}
		if (pathname.split("/")[1] == "pages") {
			ret = false;
			if (pathname.split("/")[2] == page) {
				ret = true;
			}
		}
		if (
			pathname.split("/")[1].includes("eshop") &&
			page.includes("eshop")
		) {
			ret = true;
		}
		return ret;
	};

	const firstLevel = async () => {
		let ret = [];
		await pages.forEach((element) => {
			if (!ret.includes(element.url.split("/")[1])) {
				ret.push(element.url.split("/")[1]);
			}
		});
		return ret;
	};

	const secondLevel = async (page) => {
		let ret = [];
		await pages.forEach((element) => {
			if (element.url.split("/")[1] == page) {
				if (element.url.split("/")[2]) {
					if (
						!ret.includes(element.url.split("/")[2].split("?")[0])
					) {
						ret.push(element.url.split("/")[2]);
					}
				}
			}
		});
		return ret;
	};

	const thirdLevel = (page) => {
		let ret = [];
		pages.forEach((element) => {
			if (element.url.split("/")[2] == page) {
				if (!ret.includes(element.url.split("/")[3])) {
					ret.push(element.url.split("/")[3]);
				}
			}
		});
		return ret;
	};

	const signOut = () => {
		let expires = "expires=Thu, 01 Jan 1970 00:00:00 UTC";
		document.cookie = "token=;" + expires + ";path=/";
		document.cookie = "email=;" + expires + ";path=/";
	};

	const getCookie = (cname) => {
		let name = cname + "=";
		let decodedCookie = decodeURIComponent(document.cookie);
		let ca = decodedCookie.split(";");
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) == " ") {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	};

	const getEuro = (num) => {
		console.log('num', num, $order)
		const euros = new Intl.NumberFormat(`sk-SK`, {
			currency: `EUR`,
			style: "currency",
		}).format(num);
		return euros;
	};

	const sumCart = () => {
		$order.products.forEach((product) => {
			sumPrice = sumPrice + product.price;
		});
		sumPrice = sumPrice;
		return sumPrice;
	};

	const setActive = async (path) => {
		window.location.replace(path);
	};
	const goToEshop = () => {
		router.redirect("/eshop");
	};

	const getMenuTitle = (path) => {
		let title = "";
		pages.forEach((page) => {
			if (page.url == path) {
				title = page.title;
			}
			if (page.url.includes(path + "?")) {
				title = page.title;
			}
		});
		return title;
	};

	const getSecondUrl = (path) => {
		let url = "";
		pages.forEach((page) => {
			if (page.url.includes(path + "?")) {
				url = page.url;
			}
		});
		return url;
	};

	const openSecondMenu = async (e) => {
		firstSelected = e;
		secondLevelRoutes = [];
		thirdLevelRoutes = [];
		secondLevelRoutes = await secondLevel(e);

		// for (let index = 0; index < secondLevelRoutes.length; index++) {
		// 	const element = secondLevelRoutes[index];
		// }
		secondLevelRoutes.forEach((element) => {
			thirdLevelRoutes.push({ [element]: thirdLevel(element) });
		});

		if (secondLevelRoutes[0] == undefined) {
			showSecondLevel = false;
		} else {
			showSecondLevel = true;
		}
	};

	const closeSecondLevel = () => {
		showSecondLevel = false;
	};

	const setSearch = () => {
		$search = searchString
	};

	
</script>

<svelte:body on:viewportchanged={setMenu} />
<main>
	{#each topBanner as banner }
		{#if banner.show}
		<div class="alert alert-{banner.type} m-0 text-center" role="alert">
			<h4 class="mx-5 px-5">
				{banner.text}
			</h4>
		  </div>
		{/if}
	{/each}

	<!-- Topline  -->
	<!-- <div class="row p-2 justify-content-md-center align-items-center shadow-sm gx-0">
		<div class="col col-lg-2">
		
		</div>
		<div class="col-md-5 text-secondary">
			<div class="text-secondary text-end">
				INFOLINKA PO-SO 08:00 - 17:00 TEL. 0903 999 773 INFO@BUGARSRO.SK BRATISLAVSKÁ 21, 931 01 ŠAMORÍN
			</div>
		</div>
		<div class="col col-lg-2 text-end">
		  <a class="text-secondary fw-bold p-3" href="/prihlasenie">PRIHLÁSIŤ SA</a>
		  <a class="text-secondary fw-bold" href="/registracia">REGISTRÁCIA</a>
		</div>
	</div> -->

	<!-- Midleline -->
	<div
		class="row p-3 justify-content-center align-items-center gx-0 bg-secondary row-cols-auto"
	>
		<div class="col col-md-auto my-4 px-5">
			<a href="/">
				<img
					src="/brand/Bugar_Logo_new-transparent.png"
					width="150"
					alt="Logo"
				/>
			</a>
		</div>
		<div class="col col-md-6 text-secondary">
			<div class="input-group input-group-lg">
				<input
					type="text"
					class="form-control"
					placeholder="Hľadaj ..."
					aria-label="Hľadaj ..."
					aria-describedby="button-addon2"
					bind:value={searchString}
					on:change={setSearch}
					on:click={setSearch}
					on:click={goToEshop}
					on:click={closeSecondLevel}
				/>
				<button
					class="btn btn-warning text-white"
					type="button"
					id="button-addon2"
					on:click={setSearch}
					on:click={goToEshop}
					on:click={closeSecondLevel}
				>
					<i
						class="bi bi-search"
						style="font-size: 1rem; color: white;"
					/>
				</button>
			</div>
		</div>
		<div class="col col-md-auto text-end ps-5 pe-3">
			<a
				class="text-warning text-decoration-none fs-2 position-relative"
				href="/checkout/information"
			>
				<span class="h2"
					>{getEuro($order.sum)}
					<i class="bi bi-cart-fill " style="font-size: 2rem;" />
					{#if $order.products.length > 0}
						<span
							class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
							style="font-size:.6rem;"
						>
							{$order.products.length}
						</span>
					{/if}
				</span>
			</a>
			<span class="dropdown m-4">
				{#if isLoged}
					<a
						class="dropdown-toggle text-warning"
						id="dropdownMenuButton1"
						data-bs-toggle="dropdown"
						aria-expanded="false"
					>
						<i
							class="bi bi-person-circle text-warning"
							style="font-size: 2.2rem;"
						/>
					</a>
					<ul
						class="dropdown-menu"
						aria-labelledby="dropdownMenuButton1"
					>
						<li>
							<a class="dropdown-item" href="/prihlasenie"
								>{email}</a
							>
						</li>
						<li>
							<a
								class="dropdown-item"
								href="/prihlasenie"
								on:click={signOut}>Odhasit</a
							>
						</li>
					</ul>
				{:else}
					<a href="/prihlasenie">
						<i
							class="bi bi-person-circle text-warning"
							style="font-size: 2.2rem;"
						/>
					</a>
				{/if}
			</span>
		</div>
	</div>

	{#if !mobileMenu}
		<!-- Bottomline desktop -->
		<div
			class="row justify-content-md-center align-items-center bg-secondary gx-0"
		>
			<div class="col-md-7">
				<ul
					class="nav nav-pills bg-secondary bugar-font align-items-end"
				>
					{#if pathname.split("/")[1] == ""}
						<li class="nav-item position-relative mx-3">
							<a
								class="nav-link text-white pt-3 activeUrl"
								href="/"
							>
								<i
									class="bi bi-house-fill"
									style="font-size: 1.5rem;"
								/>
							</a>
						</li>
					{:else}
						<li class="nav-item position-relative mx-3">
							<a class="nav-link text-white pt-3" href="/">
								<i
									class="bi bi-house-fill"
									style="font-size: 1.5rem;"
								/>
							</a>
						</li>
					{/if}
					{#if firstLevelRoutes}
						{#each firstLevelRoutes as page}
							{#if isActiveMenu(page)}
								<li class="nav-item position-relative">
									<a
										class="nav-link text-white pt-3 activeUrl"
										href="/pages/{page}"
										on:click={setActive("/pages/" + page)}
										on:mouseover={openSecondMenu(page)}
										on:focus={openSecondMenu(page)}
										>{getMenuTitle("/" + page)}</a
									>
								</li>
							{:else}
								<li class="nav-item position-relative">
									<a
										class="nav-link text-white pt-3"
										href="/pages/{page}"
										on:click={setActive("/pages/" + page)}
										on:mouseover={openSecondMenu(page)}
										on:focus={openSecondMenu(page)}
										>{getMenuTitle("/" + page)}</a
									>
								</li>
							{/if}
						{/each}
					{/if}
				</ul>
				{#if showSecondLevel}
					<div
						class="shadow topmenu position-absolute start-0 w-100 h-auto bg-light"
						on:mouseleave={closeSecondLevel}
					>
						<div class="container h-auto">
							<div class="row row-cols-auto">
								{#each secondLevelRoutes as second}
									<div
										class="col mt-3 mb-3 mx-1 lh-sm"
										style="min-width: 13rem ;"
									>
										<h5 class="mb-4">
											<a
												class="text-body link-menu"
												on:click={setActive(
													getSecondUrl(
														"/pages/" +
															firstSelected +
															"/" +
															second
													)
												)}
												href={getSecondUrl(
													"/pages/" +
														firstSelected +
														"/" +
														second
												)}
											>
												{getMenuTitle(
													"/" +
														firstSelected +
														"/" +
														second
												)}
											</a>
										</h5>
										<!-- <hr style="min-width: 10rem;"/> -->
										{#each thirdLevelRoutes as thirdLevel}
											{#if thirdLevel.hasOwnProperty(second)}
												{#each thirdLevel[second] as third}
													{#if third}
														<p>
															<a
																class="text-dark link-menu"
																on:click={setActive(
																	"/pages/" +
																		firstSelected +
																		"/" +
																		second +
																		"/" +
																		third
																)}
																href={"/pages/" +
																	firstSelected +
																	"/" +
																	second +
																	"/" +
																	third}
																>{getMenuTitle(
																	"/" +
																		firstSelected +
																		"/" +
																		second +
																		"/" +
																		third
																)}</a
															>
														</p>
													{/if}
												{/each}
											{/if}
										{/each}
									</div>
								{/each}
							</div>
						</div>
					</div>
				{/if}
			</div>
		</div>
	{:else}
		<!-- Bottomline mobile -->

		<div
			class="position-absolute top-0 start-0"
		>
			<div class="col">
				<button
					class="nav-link text-warning fs-1 pt-3 activeUrl bg-secondary border-0"
					data-bs-toggle="offcanvas"
					data-bs-target="#mobileMenu"
					aria-controls="mobileMenu"
				>
					<i class="bi bi-list" style="font-size: 2.5rem;" />
				</button>
			</div>
		</div>
		<div
			class="offcanvas offcanvas-start"
			tabindex="-1"
			id="mobileMenu"
			aria-labelledby="mobileMenuLabel"
		>
			<div class="offcanvas-header">
				<h5 class="offcanvas-title" id="mobileMenuLabel">Menu</h5>
				<button
					type="button"
					class="btn-close"
					data-bs-dismiss="offcanvas"
					aria-label="Close"
				/>
			</div>
			<div class="offcanvas-body ">
				<div class="mb-2">
					<a class="link-menu" href="/">
						<i class="bi bi-house-fill text-secondary" style="font-size: 2rem;">
						</i>
					</a>
				</div>
				{#each firstLevelRoutes as page}
				<div class="bg-menuheader mt-1 pt-2 pb-1">
					<a class="link-menu text-body" href="/pages/{page}" on:click={setActive("/pages/" + page)}
						><h4>{getMenuTitle("/" + page)}</h4></a
					>
				</div>
					{#await secondLevel(page)}
						<p>loading</p>
					{:then seconds}
						{#each seconds as second}
						<div>
							<a
								class="btn btn-link link-menu text-secondary"
								href={getSecondUrl("/pages/" + page + "/" + second)}
								on:click={setActive("/pages/" + page + "/" + second)}
								><h5 class="mx-3 mb-0 mt-2">
									{getMenuTitle("/" + page + "/" + second)}
								</h5></a
							>
						</div>

							{#await thirdLevel(second)}
								<p>loading</p>
							{:then thirds}
								{#each thirds as third}
								<div>
									<a
										class="btn btn-link link-menu text-secondary"
										href={"/pages/" +
											page +
											"/" +
											second +
											"/" +
											third}
										on:click={setActive(
											"/pages/" +
												page +
												"/" +
												second +
												"/" +
												third
										)}
										><p class="mx-5 my-0">
											{getMenuTitle(
												"/" +
													page +
													"/" +
													second +
													"/" +
													third
											)}

										</p></a
									>
									<i class="bi bi-chevron-right float-end">
									</i>
								</div>

								{/each}
							{/await}
						{/each}
					{/await}
				{/each}
			</div>
		</div>
	{/if}
</main>

<style>
	.topmenu {
		z-index: 10;
	}
	.nav-link:hover {
		background-color: #f03337;
	}
	.bg-menuheader{
		background-color: #F5F5F5;
	}
	.activeUrl {
		background-color: #ffb506;
	}
	.link-menu {
		text-decoration: none;
	}
	.link-menu:hover {
		text-decoration: underline;
	}
	@import "/css/font.css";
	@import "/css/bs-custom.css";
	@import "/css/bootstrap-icons.css";
</style>
