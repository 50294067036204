<script>
    import { onMount } from "svelte";
    import { tick } from "svelte";
    import { products } from "./storage/productsStore.js";
    import { tages } from "./storage/tagesStore.js";
    import { search } from "./storage/searchStore.js";
    import { element } from "svelte/internal";
    import Fuse from "fuse.js";

    export let pathname = "";
    let showOver = 0;
    let showTags = true;
    let tags = [];
    let productArray = [];
    let filteredProductArray = [];
    let showProductArray = [];
    let tagGroups = [];
    let filterTags = [];
    let showProducts = 100;
    let showProductBlock = "invisible";
    let showBrandBlock = "invisible";
    let showTypeBlock = "invisible";

    const options = {
        //isCaseSensitive: false,
        // includeScore: false,
        // shouldSort: true,
        // includeMatches: false,
        // findAllMatches: true,
        // minMatchCharLength: 1,
        // location: 0,
        threshold: 0.2,
        distance: 1000,
        // useExtendedSearch: false,
        // ignoreLocation: false,
        // ignoreFieldNorm: false,
        // fieldNormWeight: 1,
        keys: [
            "name",
            "tag",
            "properties.key",
            "properties.value",
            "description",
        ],
    };

    let pattern = "";
    let zePole = [];
    let fuse = new Fuse(productArray, options);

    $: if (pattern.length > 0) {
        patternCalculate();
    }

    const patternCalculate = async () => {
        resetFilter();
        filteredProductArray = await removeItem(fuse.search(pattern));
        showProductArray = filteredProductArray;
    };

    onMount(async () => {
        console.log(pathname);
        console.log("mounteeeed");
    });

    tages.subscribe((value) => {
        tags = value;
    });

    products.subscribe((value) => {
        productArray = value;
    });

    search.subscribe((value) => {
        pattern = value;
    });

    const removeItem = (arr) => {
        let ret = [];
        arr.forEach((item) => {
            ret.push(item.item);
        });
        return ret;
    };
    const getEuro = (num) => {
        const euros = new Intl.NumberFormat(`sk-SK`, {
            currency: `EUR`,
            style: "currency",
        }).format(num);
        return euros;
    };
    const setOver = (id) => {
        showOver = id;
    };
    const leaveOver = () => {
        showOver = 0;
    };

    const plusOneHundered = async () => {
        showProducts = showProducts + 100;
        filterProducts();
    };

    const filterProducts = async () => {
        filteredProductArray = productArray;
        for (let index = 0; index < filterTags.length; index++) {
            const tag = filterTags[index];
            filteredProductArray = await filteredProductArray.filter(
                (product) => product.tag.includes(tag),
            );
        }
        showProductArray = await filteredProductArray.slice(0, showProducts);
        console.log("filterProducts: ", showProductArray.length);
        //isGroupInFiltered()
    };

    const setFilterFromURL = async () => {
        const params = new URLSearchParams(window.location.search);
        let filter = params.get("filter");
        if (filter) {
            filter.split(":").forEach(async (element) => {
                if (element !== "") {
                    await addToFilter(element);
                }
            });
        }
        filterTags = filterTags;
        filteredProductArray = productArray;
    };

    const resetFilter = () => {
        filterTags = [];
        filterProducts();
        rerenderTagsMenu();
    };

    const addToFilter = async (tag) => {
        filterTags.push(tag);
        filterTags = filterTags;
        await filterProducts();
        rerenderTagsMenu();
    };

    const isInFiltered = (tag) => {
        let ret = true;
        filteredProductArray.forEach((product) => {
            if (product.tag.filter((e) => e == tag).length > 0) {
                ret = false;
            }
        });

        if (filterTags.filter((e) => e == tag).length > 0) {
            ret = true;
        }

        return ret;
    };

    const setFilterBlock = (group, show) => {
        //console.log("setFilterBlock: ",group,show);
        if (group == "PRODUKT") {
            showProductBlock = show;
        }
        if (group == "ZNAČKA") {
            showBrandBlock = show;
        }
        if (group == "TYP") {
            showTypeBlock = show;
        }
        return "";
    };

    const removeFromFilter = (tag) => {
        filterTags = filterTags.filter((e) => e !== tag);
        filterTags = filterTags;
        filterProducts();
        rerenderTagsMenu();
    };

    const rerenderTagsMenu = async () => {
        showTags = false;
        await tick();
        showTags = true;
    };

    const groupBy = (arrayObjects, key) => {
        return arrayObjects.reduce(function (result, currentObject) {
            const val = currentObject[key];
            result[val] = result[val] || [];
            result[val].push(currentObject);
            return result;
        }, {});
    };

    $: if (tags.length > 0) {
        let ret = {};
        tagGroups = groupBy(tags, "group");
        ret["ZNAČKA"] = tagGroups["ZNAČKA"];
        ret["PRODUKT"] = tagGroups["PRODUKT"];
        ret["TYP"] = tagGroups["TYP"];
        // ret['DOSTUPNOSŤ'] = tagGroups['DOSTUPNOSŤ']

        tagGroups = ret;
        console.log("tagGroups", Object.keys(tagGroups));
        //tagGroups = Object.keys(groupBy(tags, 'group'));
    }

    $: if (productArray.length > 0) {
        setFilterFromURL();
        filterProducts();
        fuse = new Fuse(productArray, options);
    }
</script>

<main class="bg-light">
    <div class="container pb-3 bg-body">
        <div class="row justify-content-md-center p-3">
            <div class="col-md-auto px-md-0 text-black-50">
                <!-- {#if pattern.length > 0} -->
                <button
                    type="button"
                    class="btn btn-secondary btn-light m-1 bg-body"
                    disabled
                    ><strong>{filteredProductArray.length} položiek</strong
                    ></button
                >
                <!-- {/if} -->
            </div>
            <div class="col-md fs-5">
                {#each filterTags as tag}
                    <span
                        class="badge rounded-pill bg-warning text-white m-1 mt-2"
                        tabindex="0"
                        role="button"
                        on:click={removeFromFilter(tag)}
                    >
                        {tag}
                        <i class="bi bi-x-square"></i>
                    </span>
                {/each}
            </div>
            <div class="col-md-auto float-end">
                <button class="btn btn-link float-end" on:click={resetFilter}
                    >Zrušiť filter</button
                >
            </div>
            <div class="col-md-auto float-end">
                <button
                    class="btn btn-primary"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                >
                    Filter
                    {#if filterTags.length > 0}
                        <span class="badge bg-secondary"
                            >{filterTags.length}</span
                        >
                    {:else}
                        <i class="bi bi-sliders2"></i>
                    {/if}
                </button>
            </div>

            <div class="col-md-auto float-end">
                <select class="form-select" aria-label="Order select">
                    <option value="favorite" selected>Podľa obľúbenosti</option>
                    <option value="price-low">Od najnižšej ceny</option>
                    <option value="newest">Od najnovších</option>
                    <option value="actions">Špeciálne ponuky</option>
                </select>
            </div>
        </div>

        <!-- WIZARD -->
        <div class="col-md-auto mx-md-6 d-none d-sm-block">
            {#if showTags}
                <div class="row m-md-5">
                    <div class="col mx-md-5">
                        {#each Object.keys(tagGroups) as tag}
                            {setFilterBlock(tag, "invisible")}
                            {#if tag !== "route"}
                                <!-- FARBA -->
                                {#if tag == "ZNAČKA"}
                                    <h6
                                        class="border-bottom my-md-2 {showBrandBlock}"
                                    >
                                        {tag}
                                    </h6>
                                    <div
                                        class="row row-cols-lg-8 row-cols-sm-4 row-cols-xs-4 row-cols-4"
                                    >
                                        {#each tagGroups[tag] as tags}
                                            {#if !isInFiltered(tags.name)}
                                                {setFilterBlock(tag, "visible")}
                                                <div class="col col-1 p-2 fs-5">
                                                    <span
                                                        class="badge rounded-pill bg-warning text-white w-100"
                                                        tabindex="0"
                                                        role="button"
                                                        disabled={isInFiltered(
                                                            tags.name,
                                                        )}
                                                        on:click={addToFilter(
                                                            tags.name,
                                                        )}>{tags.name}</span
                                                    >
                                                </div>
                                            {/if}
                                        {/each}
                                    </div>
                                {/if}
                                {#if tag == "PRODUKT"}
                                    <h6
                                        class="border-bottom my-md-2 {showProductBlock}"
                                    >
                                        {tag}
                                    </h6>
                                    <div
                                        class="row row-cols-lg-8 row-cols-sm-4 row-cols-xs-4 row-cols-4"
                                    >
                                        {#each tagGroups[tag] as tags}
                                            {#if !isInFiltered(tags.name)}
                                                {setFilterBlock(tag, "visible")}
                                                <div class="col col-1 p-2 fs-5">
                                                    <span
                                                        class="badge rounded-pill bg-warning text-white w-100"
                                                        tabindex="0"
                                                        role="button"
                                                        disabled={isInFiltered(
                                                            tags.name,
                                                        )}
                                                        on:click={addToFilter(
                                                            tags.name,
                                                        )}>{tags.name}</span
                                                    >
                                                </div>
                                            {/if}
                                        {/each}
                                    </div>
                                {/if}
                                {#if tag == "TYP"}
                                    <h6
                                        class="border-bottom my-md-2 {showTypeBlock}"
                                    >
                                        {tag}
                                    </h6>
                                    <div
                                        class="row row-cols-lg-8 row-cols-sm-4 row-cols-xs-4 row-cols-4"
                                    >
                                        {#each tagGroups[tag] as tags}
                                            {#if !isInFiltered(tags.name)}
                                                {setFilterBlock(tag, "visible")}
                                                <div class="col col-1 p-2 fs-5">
                                                    <span
                                                        class="badge rounded-pill bg-warning text-white w-100"
                                                        tabindex="0"
                                                        role="button"
                                                        disabled={isInFiltered(
                                                            tags.name,
                                                        )}
                                                        on:click={addToFilter(
                                                            tags.name,
                                                        )}>{tags.name}</span
                                                    >
                                                </div>
                                            {/if}
                                        {/each}
                                    </div>
                                {/if}
                            {/if}
                        {/each}
                    </div>
                </div>
            {/if}
        </div>

        <div
            class="offcanvas offcanvas-end"
            tabindex="-1"
            id="offcanvasRight"
            aria-labelledby="offcanvasRightLabel"
        >
            <div class="offcanvas-header" style="display:block">
                <div class="row justify-content-between">
                    <div class="col-auto">
                        <h5 id="offcanvasRightLabel">
                            Filtrovat podľa parametrov:
                        </h5>
                    </div>
                    <div class="col-auto float-end">
                        <button
                            type="button"
                            class="btn-close text-reset mx-2"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        ></button>
                    </div>
                </div>

                <div
                    class="row"
                    style="box-shadow:0px 1rem 1rem rgb(0 0 0 / 15%)"
                >
                    <div class="col-12 my-4">
                        {#each filterTags as tag}
                            <button
                                class="btn btn-outline-primary m-1"
                                on:click={removeFromFilter(tag)}
                            >
                                {tag}
                                <i class="bi bi-x-square"></i>
                            </button>
                        {/each}
                    </div>
                    <div class="col">
                        <a class="float-end" href=" " on:click={resetFilter}
                            >Zrušiť filter</a
                        >
                        <!-- <button class="btn btn-primary btn-lg" on:click="{setFilterFromURL}" data-bs-dismiss="offcanvas">Použiť filter</button> -->
                    </div>
                </div>
            </div>
            <div class="offcanvas-body position-relative">
                <!-- middle -->
                {#if showTags}
                    <div class="row overflow-auto">
                        <div class="col-md">
                            {#each Object.keys(tagGroups) as tag}
                                {#if tag !== "route"}
                                    <!-- FARBA -->
                                    {#if tag == "TYP"}
                                        <h3 class="border-bottom">{tag}</h3>
                                        <div class="my-4">
                                            {#each tagGroups[tag] as tags}
                                                {#if !isInFiltered(tags.name)}
                                                    <button
                                                        class="btn btn-outline-primary m-1"
                                                        disabled={isInFiltered(
                                                            tags.name,
                                                        )}
                                                        on:click={addToFilter(
                                                            tags.name,
                                                        )}>{tags.name}</button
                                                    >
                                                {/if}
                                            {/each}
                                        </div>
                                    {:else}
                                        <h3 class="border-bottom">{tag}</h3>
                                        <div class="my-4">
                                            {#each tagGroups[tag] as tags}
                                                {#if !isInFiltered(tags.name)}
                                                    <button
                                                        class="btn btn-outline-primary m-1"
                                                        disabled={isInFiltered(
                                                            tags.name,
                                                        )}
                                                        on:click={addToFilter(
                                                            tags.name,
                                                        )}>{tags.name}</button
                                                    >
                                                {/if}
                                            {/each}
                                        </div>
                                    {/if}
                                {/if}
                            {/each}
                        </div>
                    </div>
                {/if}

                <!-- end -->
                <!-- <div class="row position-absolute bottom-0 start-0 shadow">
            <div  class="col-md-12 float-end">
                <a href=" " on:click="{resetFilter}" data-bs-dismiss="offcanvas">Zrušiť filter</a>
                <button class="btn btn-primary btn-lg" on:click="{filterProducts}" data-bs-dismiss="offcanvas">Použiť filter</button>
            </div>
        </div> -->
            </div>
        </div>

        <div class="row row-cols-1 row-cols-md-2 row-cols-xl-4 g-4 m-3 mt-0">
            {#each showProductArray as product}
                <div class="col">
                    <div class="shadow">
                        <a
                            class="text-decoration-none position-relative"
                            href="/product/{product.url}"
                        >
                            <div class="text-black p-3 pb-0">
                                <h5
                                    class="text-truncate"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="bottom"
                                    title={product.name}
                                >
                                    {product.name}
                                </h5>
                                <div
                                    class="w-100 text-center position-relative overflow-hidden"
                                    on:mouseover={setOver(product.id)}
                                    on:focus={setOver(product.id)}
                                    on:mouseleave={leaveOver}
                                >
                                    {#if showOver == product.id}
                                        <div class="w-100 fadeIn">
                                            <img
                                                loading="lazy"
                                                src={product.images[1]}
                                                class=""
                                                style="height: 18rem;"
                                                alt={product.name}
                                            />
                                        </div>
                                    {:else}
                                        <div class="w-100">
                                            <img
                                                loading="lazy"
                                                src={product.images[0]}
                                                class=""
                                                style="height: 18rem;"
                                                alt={product.name}
                                            />
                                        </div>
                                    {/if}

                                    <div
                                        class="position-absolute top-0 start-0 fs-5 text-start"
                                    >
                                        {#if product.action}
                                            <p
                                                class="badge rounded-pill bg-primary text-white mb-0"
                                            >
                                                <i
                                                    class="bi bi-percent"
                                                    style="font-size: .7rem;"
                                                ></i>
                                                Lepšia cena
                                            </p>
                                            <br />
                                        {/if}
                                        {#if product.tag.filter((tag) => tag == "Na sklade").length > 0}
                                            <p
                                                class="badge rounded-pill bg-success text-white mb-0"
                                            >
                                                <i
                                                    class="bi bi-shop"
                                                    style="font-size: .7rem;"
                                                ></i>
                                                Na sklade
                                            </p>
                                            <br />
                                        {/if}
                                    </div>
                                </div>
                            </div>
                            <div class="w-100 border-bottom"></div>

                            <div
                                class="row m-1 text-black"
                                style="height: 6rem;"
                            >
                                <div class="col-auto p-0">
                                    <div>Produktový kód</div>
                                    <div>
                                        <strong>
                                            {product.code}
                                        </strong>
                                    </div>
                                </div>
                                <div class="col m-1 position-relative">
                                    <div class="position-absolute top-0 end-0">
                                        Cena s DPH
                                    </div>
                                    {#if product.action}
                                        <div
                                            class="position-absolute top-50 end-0 translate-middle-y"
                                        >
                                            <span
                                                class="h6 text-black-50 text-decoration-line-through"
                                                >{getEuro(product.price)}
                                            </span>
                                            <span class="h4">
                                                {getEuro(
                                                    product.actionprice,
                                                )}</span
                                            >
                                        </div>
                                        <div
                                            class="position-absolute bottom-0 end-0"
                                        >
                                            bez DPH {getEuro(
                                                product.actionprice / 1.2,
                                            )}
                                        </div>
                                    {:else}
                                        <div
                                            class="position-absolute top-50 end-0 translate-middle-y"
                                        >
                                            <h3 class="float-end">
                                                {getEuro(product.price)}
                                            </h3>
                                        </div>
                                        <div
                                            class="position-absolute bottom-0 end-0"
                                        >
                                            bez DPH {getEuro(
                                                product.price / 1.2,
                                            )}
                                        </div>
                                    {/if}
                                </div>
                            </div>

                            <!-- <div class="row text-black p-3 pt-1 pb-1">
                        <div class="col h-100">
                            <p class="mb-1">
                                Produktový kód 
                            </p>
                            <h6>
                                {product.code}
                            </h6>
                        </div>
                        <div class="col h-100">
                            <p class="float-end mb-1">Cena s DPH</p>
                            {#if product.action }
                            <div class="float-end">
                                <span class="float-end text-black-50 text-decoration-line-through fs-3">{getEuro(product.price)} </span>
                                <span class="float-end fs-3"> {getEuro(product.actionprice)}</span>
                            </div>
                                <p class="float-end mb-0">bez DPH {getEuro(product.actionprice/1.20)}</p>
                            {:else}
                                <h3 class="float-end">{getEuro(product.price)}</h3>
                                <p class="float-end mb-0">bez DPH {getEuro(product.price/1.20)}</p>
                            {/if}
                        </div>
                    </div> -->
                        </a>
                    </div>
                </div>
            {/each}
        </div>

        <div class="row justify-content-md-center p-md-5 px-md-3 bg-body">
            <div class="col">
                <button
                    type="button"
                    class="btn btn-secondary btn-light m-1 bg-body"
                    disabled
                    ><strong
                        >{showProductArray.length} zobrazených položiek</strong
                    ></button
                >
            </div>
            <div class="col-md-auto">
                {#if showProductArray}
                    {#if showProductArray.length >= showProducts}
                        <button
                            class="btn btn-primary btn-lg"
                            on:click={plusOneHundered}
                        >
                            Ďalšie produkty
                        </button>
                    {/if}
                {/if}
            </div>
            <div class="col"></div>
        </div>
    </div>
</main>

<style>
    .container {
        max-width: 1680px !important;
    }

    .fadeIn {
        opacity: 0;
        cursor: pointer;
        transition: 0.2s all ease-in-out;
    }

    .fadeIn:hover {
        opacity: 1;
    }

    @media (min-width: 992px) {
        .row-cols-lg-8 > * {
            -ms-flex: 0 0 12.5%;
            flex: 0 0 12.5%;
            max-width: 12.5%;
        }
    }
    @import "/css/font.css";
    @import "/css/bs-custom.css";
    @import "/css/bootstrap-icons.css";
</style>
