import { writable } from 'svelte/store';

export const products = writable(
  {
    "images": [
      ""
    ],
    "documents": [
      ""
    ],
    "name": "",
    "tag": [
      ""
    ],
    "description": "",
    "order": 0,
    "active": false,
    "properties": [
      {
        "key": "",
        "value": ""
      }
    ],
    "price": 0,
    "actionprice": 0,
    "action": false,
    "code": "",
    "note": "",
    "id": 0
  }
);