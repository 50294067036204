<script>
	var disabled = true;
	var showProgress = false;
	var loged = false;
	var formVal = {
		email_valid: false
	}
	var user = {
    	email: ""
	}

	function emailValidator (value) {
            formVal.email_valid = (value && !!value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) || false
            return (value && !!value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) || false
        }

		$: disabled = formVal.email_valid

	const requestAPI = async () => {
		showProgress = true;
		const options = {
			method: "PUT",
			body: JSON.stringify(user),
			headers: {
				"Content-Type": "application/json",
				"Accept": "application/json",
			},
		};
		try {
			let res = await fetch(`/customer-api/renew`, options)
				.then((res) => res.json())
				.then((data) => {
					if(data.message){
						loged = true;
						showProgress = false;  
					}else{
						showProgress = false;
						alert("Unauthorized", data);

					}
					
				})
				.catch((err) => {
					showProgress = false;
					alert("Unauthorized", err);
				});
		} catch (err) {
			showProgress = false;
			console.log(err);
		}
	};


</script>

{#if loged}
<div class="row justify-content-md-center align-items-center">
<div class="text-center align-middle" style="width: 28rem; height: 20rem;">
	<div>
		<i
			class="bi bi-envelope-check-fill"
			style="font-size: 3rem; color: gray;"
		/>
		<h5>
			Pokračuj otvorenim linku v potvrdzovacom emaili.
		</h5>
	</div>
</div>
</div>
{/if}
{#if !loged}
<div class="row justify-content-md-center align-items-center">
	<div class="text-center align-middle" style="width: 28rem;">
		<div>
			<h2 class="mt-1">Obnova účtu</h2>
		</div>
		<div class="mt-1">
			Už máš účet? <span><a href="/prihlasenie">Prihlásenie</a></span>
		</div>

		<div class="form-floating m-3 mx-5">
			<input
				type="email"
				class="form-control"
				id="floatingInput"
				placeholder="name@example.com"
				bind:value={user.email}
				on:change={emailValidator(user.email)}
			/>
			<label for="floatingInput">Emailová adresa</label>
		</div>
		<div class="my-4 mx-5 d-grid d-md-block">
			<button disabled={!disabled}  class="btn-lg btn-primary" on:click|preventDefault={requestAPI}>
				{#if showProgress}
				<span class="spinner-border spinner-border-sm" role="status" />
				{/if}
				Obnoviť účet</button
			>
		</div>
	</div>
</div>
{/if}

<style>
	@import "/css/font.css";
	@import "/css/bs-custom.css";
</style>
