<script>
	export let token;
	var disabled = true;
	var showProgress = false;
	var loged = false;
	var passwordInput = true;

	var formVal = {
		password_valid: false,
	};
	var user = {
		password: "",
	};

	const toagleInputPassword = () => {
		passwordInput = !passwordInput;
	};

	function passwordValidator(value) {
		formVal.password_valid =
			(value && !!value.match(/[a-zA-Z0-9$&+,:=?@#'.^*%!]{7,20}$/)) ||
			false;
		if (formVal.password == formVal.repeatpassword) {
			if (formVal.password_valid) {
				console.log("passwords OK");
				return true;
			} else {
				formVal.password_valid = false;
				return false;
			}
		}
	}

	$: disabled = formVal.password_valid;

	const requestAPI = async () => {
		showProgress = true;
		const options = {
			method: "PUT",
			body: JSON.stringify(user),
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		};
		try {
			let res = await fetch(`/customer-api/renew/${token}`, options)
				.then((res) => res.json())
				.then((data) => {
					if (data.message) {
						loged = true;
						showProgress = false;
					} else {
						showProgress = false;
						alert("Unauthorized", data);
					}
				})
				.catch((err) => {
					showProgress = false;
					alert("Unauthorized", err);
				});
		} catch (err) {
			showProgress = false;
			console.log(err);
		}
	};
</script>

{#if loged}
	<div class="row justify-content-md-center align-items-center">
		<div
			class="text-center align-middle"
			style="width: 28rem; height: 20rem;"
		>
			<div>
				<i
					class="bi bi-envelope-check-fill"
					style="font-size: 3rem; color: gray;"
				/>
				<h5>
					Pokračuj na <span
						><a href="/prihlasenie">Prihlásenie</a></span
					>.
				</h5>
			</div>
		</div>
	</div>
{/if}
{#if !loged}
	<div class="row justify-content-md-center align-items-center">
		<div class="text-center align-middle" style="width: 28rem;">
			<div>
				<h2 class="mt-1">Zmena hesla</h2>
			</div>
			<div class="my-3">
				7 az 20 znakov, moze obsahovať $&+,:=?@#'.^*%!-
			</div>

			<div class="row justify-content-md-center align-items-center">
				<div class="col form-floating">
					{#if passwordInput}
						<input
							type="password"
							class="form-control"
							id="floatingInput"
							bind:value={user.password}
							on:change={passwordValidator(user.password)}
						/>
						<label for="floatingInput">Nové heslo</label>
					{:else}
						<input
							type="text"
							class="form-control"
							id="floatingInput"
							bind:value={user.password}
							on:change={passwordValidator(user.password)}
						/>
						<label for="floatingInput">Nové heslo</label>
					{/if}
				</div>
				<div class="col-md-auto">
					<button
						class="btn-lg btn-primary"
						on:click={toagleInputPassword}
					>
						<i class="bi bi-eye" />
					</button>
				</div>
			</div>

			<div class="my-4 mx-5 d-grid d-md-block">
				<button
					disabled={!disabled}
					class="btn-lg btn-primary"
					on:click|preventDefault={requestAPI}
				>
					{#if showProgress}
						<span
							class="spinner-border spinner-border-sm"
							role="status"
						/>
					{/if}
					Zmeniť heslo</button
				>
			</div>
		</div>
	</div>
{/if}

<style>
	@import "/css/font.css";
	@import "/css/bs-custom.css";
</style>
