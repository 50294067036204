<script>
    import { onMount, onDestroy } from 'svelte';

    let pages = [];
    let page = "";
    let pathname = "";

    // Function to fetch pages data
    async function fetchPages() {
        const res = await fetch('/pages-api/pages');
        pages = await res.json();
    }

    // Function to find page based on pathname
    function findPath(page) {
        return page.url === '/' + pathname;
    }

    // Function to set the page based on pathname
    async function setPage() {
        if (pages.length > 0) {
            page = pages.find(findPath);
            if (!page) {
                window.location.replace('/404'); // Navigate to 404 if page not found
            }
        }
    }

    // Watch for changes in pathname and update page accordingly
    async function handlePathnameChange() {
        pathname = window.location.pathname.split('/pages/')[1];
        await setPage();
    }

    // Fetch pages data and set initial page on mount
    onMount(async () => {
        await fetchPages();
        await handlePathnameChange();
    });

    // Watch for changes in pathname
    const pathnameWatcher = setInterval(handlePathnameChange, 1000);

    // Cleanup function to clear the interval
    onDestroy(() => {
        clearInterval(pathnameWatcher);
    });
</script>

<main>
    <div class="container">
        {#if page}
            {@html page.html} 
        {/if}
    </div>
</main>

<style>
    @import "/css/font.css";
    @import "/css/bs-custom.css";
    @import "/css/bootstrap-icons.css";
</style>
