import { writable } from 'svelte/store';

export const order = writable(JSON.parse(localStorage.getItem("order")) ||  {
  "id": 0,
  "user": "",
  "products": [],
  "state": "uncopleted",
  "createdAt": "",
  "vs": "",
  "address": {
    "name":"",
    "lastname":"",
    "compannyname":"",
    "street":"",
    "zip":"",
    "city":"",
  },
  "invoiceaddress": {
    "name":"",
    "lastname":"",
    "compannyname":"",
    "street":"",
    "zip":"",
    "city":"",
  },
  "ico":"",
  "dic":"",
  "icdic":"",
  "note":"",
  "email":"",
  "msisdn":"",
  "sum":0,
  "qrimage":"",
  "isShipping":true,
  "selectedPayment": 'prevodom',
  "selectedShipping": 'kurier',
});
order.subscribe(val => localStorage.setItem("order", JSON.stringify(val)));




// export const order = writable(
//     {
//         "id": 0,
//         "user": "",
//         "products": [],
//         "state": "uncopleted",
//         "createdAt": "",
//         "vs": "",
//         "address": {
//           "name":"",
//           "lastname":"",
//           "compannyname":"",
//           "street":"",
//           "zip":"",
//           "city":"",
//         },
//         "invoiceaddress": {
//           "name":"",
//           "lastname":"",
//           "compannyname":"",
//           "street":"",
//           "zip":"",
//           "city":"",
//         },
//         "ico":"",
//         "dic":"",
//         "icdic":"",
//         "note":"",
//         "email":"",
//         "msisdn":"",
//         "sum":0,
//         "qrimage":"",
//         "isShipping":true
//       }
// );