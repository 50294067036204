<script>
	import { onMount } from "svelte";
	import { tick } from 'svelte';
	var disabled = true;
	var showProgress = false;
	var loged = false;
	var link = "";
	var formVal = {
		email: "",
		password: "",
		repeatpassword: "",
		read: false,
		email_valid: true,
		password_valid: true,
	};

	onMount(async () => {

	});

	function emailValidator(value) {
		
		formVal.email_valid =
			(value &&
				!!value.match(
					/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				)) ||
			false;
		return (
			(value &&
				!!value.match(
					/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				)) ||
			false
		);
	}

	function passwordValidator(value) {
		
		formVal.password_valid =
			(value &&
				!!value.match(/[a-zA-Z0-9$&+,:=?@#'.^*%!]{7,20}$/)) ||
			false;
		if (formVal.password==formVal.repeatpassword){
			if (formVal.password_valid){
				console.log("passwords OK");
				return true;
			}else {
				formVal.password_valid = false;
				return false;
			}
		}
	}

	$: disabled = formVal.email_valid && formVal.password_valid && formVal.read && formVal.email !== "" && formVal.password !== "" && formVal.repeatpassword !== "";
	$: console.log(formVal.email_valid , formVal.password_valid , formVal.read , formVal.email !== "" , formVal.password !== "" , formVal.repeatpassword !== "");

	const requestAPI = async () => {
		var user = formVal;
		showProgress = true;
		const options = {
			method: "POST",
			body: JSON.stringify(user),
			headers: {
				"Content-Type": "application/json",
			},
		};
		try {
			let res = await fetch(`/customer-api/`, options)
				.then((res) => res.json())
				.then((data) => {
					if(data.message){
						link = data.message;
						loged = true;
						showProgress = false;  
					}else{
						showProgress = false;
						alert("Unauthorized", data.message);

					}
					
				})
				.catch((err) => {
					showProgress = false;
					alert("Unauthorized", err);
				});
		} catch (err) {
			showProgress = false;
			console.log(err);
		}
	};
</script>

<main class="text-center align-middle">
	{#if loged}
		<div style="width: 28rem; height: 28rem;">
			<div>
				<i
					class="bi bi-person-fill"
					style="font-size: 3rem; color: gray;"
				/>
				<h5>
					Účet aktivuj otvorenim linku v potvrdzovacom emaili.
				</h5>
			</div>
		</div>
	{/if}
	{#if !loged}
		<div style="width: 28rem;  height: 28rem;">
			<div>
				<h2 class="mt-1">Registrácia</h2>
			</div>
			<div class="mt-1">
				Už máš účet? <span><a href="/prihlasenie">Prihlásenie</a></span>
			</div>

			<div class="form-floating m-3 mx-5">
				{#if formVal.email_valid}
					<input
						bind:value={formVal.email}
						on:change={emailValidator(formVal.email)}
						autocomplete="off"
						type="email"
						class="form-control"
						name="floatingInput"
						id="floatingInput"
						placeholder="name@example.com"
						/>
					<label for="floatingInput">Emailová adresa</label>
				{:else}
					<input
						bind:value={formVal.email}
						on:change={emailValidator(formVal.email)}
						autocomplete="off"
						type="email"
						class="form-control is-invalid"
						name="floatingInput"
						id="floatingInput"
						placeholder="name@example.com"
						/>
					<label for="floatingInput">Emailová adresa</label>
					<div id="floatingInput" class="invalid-feedback">
						Zadaj správny email.
					</div>
				{/if}
			</div>
			<span>7 az 20 znakov, moze obsahovať $&+,:=?@#'.^*%!-</span>
			<div class="form-floating  m-3 mx-5">
				{#if formVal.password_valid }
				<input
					bind:value={formVal.password}
					on:change={passwordValidator(formVal.password)}
					type="password"
					class="form-control"
					id="floatingPassword"
					name="floatingPassword"
					placeholder="Password"
				/>
				<label for="floatingPassword">Heslo</label>
				{:else}
				<input
				bind:value={formVal.password}
				on:change={passwordValidator(formVal.password)}
				type="password"
				class="form-control is-invalid"
				id="floatingPassword"
				name="floatingPassword"
				placeholder="Password"
				/>
				<label for="floatingPassword">Heslo</label>
				<div id="floatingPassword" class="invalid-feedback">
					Zadaj správne heslo.
				</div>
				{/if}
			</div>
			<div class="form-floating  m-3 mx-5">
				{#if formVal.password_valid }
				<input
					bind:value={formVal.repeatpassword}
					on:change={passwordValidator(formVal.repeatpassword)}
					type="password"
					class="form-control"
					id="repeatPassword"
					placeholder="Repeat Password"
				/>
				<label for="repeatPassword">Zopakuj heslo</label>
				{:else}
				<input
				bind:value={formVal.repeatpassword}
				on:change={passwordValidator(formVal.repeatpassword)}
				type="password"
				class="form-control is-invalid"
				id="repeatPassword"
				placeholder="Repeat Password"
				/>
				<label for="repeatPassword">Zopakuj heslo</label>
				<div id="repeatPassword" class="invalid-feedback">
					Zadaj rovnaké heslo.
				</div>
				{/if}
			</div>

			<div class="row m-3 mx-5">
				<div class="col">
					<div class="form-check">
						{#if formVal.read }
						<input
						bind:checked={formVal.read}
						class="form-check-input"
						type="checkbox"
						value=""
						id="rememberme"
						/>
						<label class="form-check-label" for="rememberme">
							Čítal som a súhlasím s podmienkami používania
						</label>
						{:else}
						<input
						bind:checked={formVal.read}
						class="form-check-input is-invalid"
						type="checkbox"
						value=""
						id="rememberme"
						/>
						<label class="form-check-label" for="rememberme">
							Čítal som a súhlasím s podmienkami používania
						</label>
						<div id="rememberme" class="invalid-feedback">
							Je potrebné súhlasiť s podmienkami používania
						</div>
						{/if}

					</div>
					<span class="ml-3"><a href="podmienky">Podmienky</a></span>
				</div>
			</div>

			<div class="my-4 mx-5 d-grid d-md-block">
				<button disabled={!disabled} class="btn-lg btn-primary" on:click|preventDefault={requestAPI}>
					{#if showProgress}
					<span  class="spinner-border spinner-border-sm" role="status" />
					{/if}
					Zaregistrovať</button
				>
			</div>
		</div>
	{/if}
</main>

<style>
	@import "/css/font.css";
	@import "/css/bs-custom.css";
</style>
