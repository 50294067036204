<script>
    import { onMount } from 'svelte';
    import {fade} from 'svelte/transition'
    import { swipe } from 'svelte-gestures';
    import {marked} from 'marked';
    import  base64 from 'base-64';
    import utf8 from 'utf8';
    import { order } from './storage/orderStore.js';

    let direction;
    let quantity = 1;
    let calculatedPackages = 0;
    let squareMetersPerPackage = 0

    function calculatePackages() {
        if (quantity > 0 && squareMetersPerPackage) {
            if (!isNaN(squareMetersPerPackage)) {
                calculatedPackages = Math.ceil(quantity / squareMetersPerPackage);
                console.log(quantity / squareMetersPerPackage, quantity, squareMetersPerPackage)
            } else {
                calculatedPackages = "Obsah balenia nie je k dispozícii";
            }
        } else {
            calculatedPackages = null;
        }
    }

    let increment = () => {
        if (quantity < 9999){
            quantity += 1
            calculatePackages()
        }
    };
    let decrement = () => {
        if ( quantity > 1){
            quantity -= 1
            calculatePackages()
        }
    };

    function handler(event) {
        direction = event.detail.direction;
        if (direction == 'right') {
            backImage()
        }
        if (direction == 'left') {
            nextImage()
        }
     }

     function addProduct(product) {
		order.update(order => {
            let newOrder =  order;
            product.quantity = calculatedPackages > 0 ? calculatedPackages : quantity;
            console.log(product.quantity, calculatedPackages, quantity)
            newOrder.products = [product,...order.products] 
            newOrder.sum = 0;
            newOrder.products.forEach((product) => {
                let actPrice = 0
                if(product.action){
                    actPrice = product.actionprice
                }else{
                    actPrice = product.price
                }
                newOrder.sum = newOrder.sum + (Number(actPrice) * Number(calculatedPackages > 0 ? (squareMetersPerPackage * calculatedPackages) : product.quantity));
		    }) 
            return newOrder
        });
        backToTop()
	}

    const productpath = window.location.pathname.split('/product/')[1];
    let product = undefined;
    let pohoda = undefined;
    let carouselPhotos = [];
    onMount(async () => {
		const res =  await fetch('/products-api/products?url='+productpath);
		product = await res.json() || [{name:"",images:[]}];
        console.log(product)
        carouselPhotos = product[0].images;
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        const respohoda =  await fetch('/pohoda-api/recordset?IDS='+product[0].code);
        pohoda = await respohoda.json()
        console.log(pohoda)
        squareMetersPerPackage = parseFloat(product[0]?.properties?.find(prop => prop.key === 'Obsah balenia')?.value?.replace(',', '.'));
        calculatePackages()
	});
    let index = 0
    
    const backToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

	const nextImage = () => {
		index = (index + 1) % carouselPhotos.length
	}
    const backImage = () => {
        if (index > 0){
            index = (index - 1) % carouselPhotos.length
        }
	}
    const getEuro = (num)=>{
        const euros = new Intl.NumberFormat(`sk-SK`, {
            currency: `EUR`,
            style: 'currency',
        }).format(num);
        return euros
    }
</script>
<main class="bg-light">
<div class="container bg-body px-0">
    <div class="row justify-content-center" >
        <div class="col-md-6" style="height: 80vh; min-height: 30rem;" use:swipe={{ timeframe: 300, minSwipeDistance: 50, touchAction: 'pan-y'}} on:swipe={handler}>
            
            <div class="text-center w-100 h-100  position-relative overflow-hidden">
                {#each [carouselPhotos[index]] as src (index)}
                    <img transition:fade {src} alt="" class="w-100 position-absolute top-50 start-50 translate-middle"/>
                {/each}                
                <div class="text-center w-100 h-100 ">
                    <div on:click={backImage} tabindex="0" role="button" class="position-absolute top-50 start-0 translate-middle-y p-3 py-1 mx-2 rounded-pill well">
                        <i class="bi bi-caret-left" style="font-size: 2rem ;"></i>
                    </div>
                    <div on:click={nextImage} tabindex="0" role="button" class="position-absolute top-50 end-0 translate-middle-y p-3 py-1 mx-2 rounded-pill well">
                        <i class="bi bi-caret-right" style="font-size: 2rem;"></i>
                    </div>
                </div>
            </div>


        </div>
        <div class="col-md-6" >
            <div class="w-100 h-100  p-5 " >
                {#if product}
                <h1>{product[0].name}</h1>
                {#each product[0].tag as tag }
                <a href="/eshop?filter={tag}">
                <span class="badge rounded-pill bg-warning text-white m-1">
                    <i class="bi bi-tag" style="font-size: .7rem;"></i>
                    {tag}
                </span>
                </a>
                {/each}
                {#if pohoda}
                    {#if pohoda.length > 0}
                    {#if pohoda[0].StavZ > 0 }
                    <span class="badge rounded-pill bg-white text-success m-1 " style="font-size: 1rem;">
                        <i class="bi bi-check-circle" ></i>
                        Na sklade
                    </span>
                    {:else}
                    <span class="badge rounded-pill bg-white text-dark m-1 " style="font-size: 1rem;">
                            <i class="bi bi-check-circle"></i>
                            Na objednanie
                        </span>
                    {/if}
                    {/if}
                {/if}
                {#if product[0].properties}
                {#each product[0].properties as property }
                    <div class="row border-bottom mt-2">
                        <div class="col-md-6">
                            <h5>{property.key}</h5>
                        </div>
                        <div class="col-md-6">
                            <p>{property.value}</p>
                        </div>
                    </div>
                {/each}
                {/if }

                <div class="row border-bottom mt-2">
                    <div class="col-md-6">
                        <h5>Typ balenia</h5>
                    </div>
                    <div class="col-md-6">
                        <p>
                            {#if product[0].packageType == "BL"}
                                Balík
                            {/if}
                            {#if product[0].packageType == "FP"}
                                Paleta
                            {/if}
                            {#if product[0].packageType == "EPN"}
                                Nadrozmerná paleta
                            {/if}
                            {#if product[0].packageType == "DP"}
                                Dvojpapeta
                            {/if}
                            {#if product[0].packageType == "IC"}
                                Pre nacenenie dopravy nás kontaktujte telefonicky na +421 910 144 022 alebo e-mailom na <a target="_blank" href="/pages/napiste-nam">eshop@bugarsro.sk</a>
                            {/if}                           
                        </p>
                    </div>
                </div>

                <div class="row row-cols-auto justify-content-center align-items-end">
                    <div class="col-md">
                        {#if product[0].active}                     
                        {#if quantity > 0 && calculatedPackages > 0}
                            <div class="row border-bottom mt-2">
                                <div class="col-md-6">
                                    <h5>Budete potrebovať</h5>
                                </div>
                                <div class="col-md-6">
                                    <p>
                                        <strong>{calculatedPackages} {calculatedPackages === 1 ? "balenie" : calculatedPackages < 5 ? "balenia" : "balení"}</strong> ({(squareMetersPerPackage * calculatedPackages).toFixed(3)}m²), za výslednú cenu <b class="fs-4">{getEuro(product[0].price * (squareMetersPerPackage * calculatedPackages))}</b>
                                    </p>
                                </div>
                            </div>
                        
                        {/if}
                        <div class="input-group">
                            <button class="btn btn-primary" type="button" on:click="{decrement}" tabindex="0">
                                <i class="bi bi-dash" style="font-size: 1rem;"></i>
                            </button>
                            <input type="number" class="form-control" bind:value="{quantity}" on:change="{calculatePackages}" min="0" max="9999">
                            <button class="btn btn-primary" type="button" tabindex="0" on:click="{increment}">
                                <i class="bi bi-plus" style="font-size: 1rem;"></i>
                            </button>
                            {#if pohoda}
                            {#if pohoda.length > 0}
                                <span class="p-2">
                                    {pohoda[0].MJ}
                                </span>
                            {/if}
                            {/if}
                        </div>
                        {/if}
                    </div>

                    <div class="col-md">
                        <div class="row row-cols-auto justify-content-center">
                            <div class="col">
                                <p class="mb-1">Cena s DPH</p>
                                {#if product[0].action}
                                    <div class="h5 text-black-50 text-decoration-line-through">{getEuro(product[0].price)} </div>
                                    <div class="h3"> {getEuro(product[0].actionprice)}</div>
                                    <div class=" mb-0">bez DPH {getEuro(product[0].actionprice/1.20)}</div>
                                {:else}
                                    <h3 class="">{getEuro(product[0].price)}</h3>
                                    <p class=" mb-0">bez DPH {getEuro(product[0].price/1.20)}</p>
                                {/if}
                            </div>
                            <div class="col align-self-end">
                                {#if product[0].active}
                                    <button class="btn btn-primary btn-lg" on:click="{addProduct(product[0])}">
                                        <i class="bi bi-cart-fill m-3" style="font-size: 1rem;"></i>
                                        Pridať do košíka
                                    </button>
                                {/if}
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <pre>{JSON.stringify(product[0])}</pre> -->
                {/if}
            </div>
        </div>
    </div>
    <div class="row p-5 markedwrapper" style="margin:0rem">
        {#if product}

        {#if product[0].description}
            {@html marked(product[0].description)}
        {/if}
        {#if product[0].documents}
            {#each product[0].documents as document }
            <a href="/images/documents/{document}" target="_blank">
                    <i class="bi bi-filetype-pdf fs-5"> <span class="m-2 fs-6">{document}</span></i> 
            </a>    
            {/each}
            {/if}
        {/if}


    </div>
</div>
</main>

<style>
    input[type="number"] {
        text-align: center;
    }
    .container{
        max-width: 1680px !important;
    }
    .well {
        background-color: rgba(245, 245, 245, 0.4);
    }
    :global(div.markedwrapper) {
        background-color: black;
        color: white;
    }
    :global(div.markedwrapper img) {
        max-width: 100%;
    }
    :global(div.markedwrapper h1) {
        color: #ffb506;
    }
    :global(div.markedwrapper h2) {
        color: #ffb506;
    }
    :global(div.markedwrapper h3) {
        color: #ffb506;
    }
    :global(div.markedwrapper h4) {
        color: #ffb506;
    }
    :global(div.markedwrapper h5) {
        color: #ffb506;
    }
    :global(div.markedwrapper a) {
        color: #ffb506;
    }
    :global(img:before) {
    content: ' ';
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: url(/images/nenaslosa.png);
    }
    @import "/css/font.css";
	@import "/css/bs-custom.css";
	@import "/css/bootstrap-icons.css";
</style>