<script>
  import { onMount } from "svelte";
  import Carousel from "svelte-carousel";
  import base64 from "base-64";
  import utf8 from "utf8";
  import Viewport from "svelte-viewport-info";
  import { order } from "./storage/orderStore.js";
  // import '@beyonk/gdpr-cookie-consent-banner/dist/style.css' // optional, you can also define your own styles
  import GdprBanner from "@beyonk/gdpr-cookie-consent-banner";
  import Meta from "./Meta.svelte";

  let banner;
  let products = [];
  let showOver = 0;
  let carousel; // for calling methods of the carousel instance
  let particles = 0;
  let pages = [];
  let firstLevelRoutes = [];
  let secondLevelRoutes = [];
  let thirdLevelRoutes = [];
  let sortedByOrder;
  let filteredAction;
  let filteredNew;
  let servicesPages = [];
  let topGallery = []

  const brandBannerMapping = {
   '/montazne-sluzby': '/brand/banner/01-montazne-sluzby/montazne-sluzby-01.png',
   '/nehnutelnosti': '/brand/banner/05-nehnutelnosti/nehnutelnosti-01.png',
    '/kupelnove-studio-3D-vizualizacie': '/brand/banner/03-kupelnove-studio-3D-vizualizacie/kupelnove-studio-01.png',
    '/realizacie-na-kluc': '/brand/banner/04-realizacie-na-kluc/realizacie-na-kluc-01.png',
    '/servisne-sluzby': '/brand/banner/02-servisne-sluzby/sevisne-sluzby-01.png',
  }

  const metadata = {
    title: "BUGÁR, s.r.o. | VODA KÚRENIE PLYN",
    description:
      "Bugarsro.sk internetový obchod špecializovaný na predaj tovaru pre stavby, zariadenie domov a domácností. Špecialista na predaj kotlov, tepelných čerpadiel, radiátorov, ohrievačov, solárnych systémov, záhradného programu, obkladov, dlažieb a stavebného materiálu.",
    image: "https://bugarsro.sk/favicon.png",
    imageAlt: "Bugarsro bugarsro.sk",
    url: "bugarsro.sk",
  };

  const options = {
    /**
     * You must set the cookie name.
     **/
    cookieName: "gdpr_bugarsro",

    /**
     * The cookie configuration, such as domain and path.
     **/
    cookieConfig: {
      domain: "eshop.bugarsro.sk",
      path: "/",
    },

    /**
     * These are the top two lines of text on the banner
     * The 'description' field can include html such as links
     **/
    heading: "Nastavenie súborov cookies",
    description:
      'Na našich webových stránkach používame súbory cookies. Niektoré sú nevyhnutné na správne fungovanie webu, zatiaľ čo iné nám pomáhajú vylepšiť tento web a váš používateľský zážitok. Vďaka nim vám zobrazujeme ponuky šité na mieru a ponúkame produkty, ktoré by vás mohli zaujímať. Potrebujeme na to však váš súhlas s používaním všetkých cookies. <br> <a target="_blank" href="/pages/vsetko-o-nakupe/podmienky/zasady-pouzivania-suborov-cookies">Informácie o súboroch cookies</a>',

    /**
     * All the button labels and aria-label content.
     **/
    acceptLabel: "Súhlasím a pokračovať",
    rejectLabel: "Nesúhlasím",
    settingsLabel: "Podrobné nastavenie",
    closeLabel: "Zatvoriť",
    editLabel: "Upraviť cookies",

    /**
     * These are the default opt-ins and their descriptions.
     * When value is set to true, the option will automatically be checked on load.
     *
     * If you want to hide a category, simply set it to false, e.g. 'marketing: false'
     **/
    choices: {
      necessary: {
        label: "Technické súbory cookies",
        description:
          "Technické súbory cookies sú nevyhnutné pre správne fungovanie našej webovej stránky.",
        value: true,
      },
      tracking: {
        label: "Personalizované cookies",
        description:
          "Personalizované cookies používame, aby sme prispôsobili náš obchod vašim potrebám a záujmom a pripravili tak pre vás lepšie nákupné skúsenosti.",
        value: true,
      },
      analytics: {
        label: "Analytické cookies",
        description:
          "Analytické cookies nám umožňujú meranie výkonu našej webovej stránky a našich reklamných kampaní. ",
        value: true,
      },
      marketing: {
        label: "Marketingové cookies",
        description:
          "Reklamné cookies môžu byť našej stránke nastavené prostredníctvom našich reklamných partnerov. Tieto spoločnosti ich môžu použiť na vytvorenie profilu Vašich záujmov a na zobrazenie relevantných reklám na iných stránkach.",
        value: true,
      },
    },

    /**
     * Show an icon to edit cookies later, when banner is closed.
     **/
    showEditIcon: false,

    /**
     * These are the functions which are run if a user opts-in to that category.
     * You should drop your cookies here (or set a variable to control the later dropping of cookies.
     *
     * If you are using svelte, you can use events instead - see the Svelte section below.
     **/
    categories: {
      analytics: function () {
        console.log("No analytics cookies specified");
      },
      tracking: function () {
        console.log("No tracking cookies specified");
      },
      marketing: function () {
        console.log("No marketing cookies specified");
      },
      necessary: function () {
        console.log("No necessary cookies specified");
      },
    },
  };

  onMount(async () => {
    const resTopGallery = await fetch("/gallery-api/gallery?id=6");
    topGallery = await resTopGallery.json();
    console.log("topGallery",topGallery)
    topGallery = topGallery[0]
    const res = await fetch("/products-api/products");
    products = await res.json();
    const resPages = await fetch("/pages-api/pages");
    pages = await resPages.json();
    servicesPages = await pages.filter((page) => page.menu == "služby");
    console.log(servicesPages);
    pages = await pages.filter((page) => page.menu == "bočné");
    pages = await pages.sort((a, b) =>
      Number(a.order) > Number(b.order) ? 1 : -1,
    );

    firstLevelRoutes = await firstLevel();

    sortedByOrder = await sortByOrder(products);
    filteredAction = await filterAction(products);
    filteredNew = await filterNew(products);

    setParticles();
  });

  function initAnalytics() {
    // do something with segment.io or google analytics etc
  }

  const setParticles = (num) => {
    if (Viewport.Width < 768) {
      particles = 1;
    }
    if (Viewport.Width >= 768) {
      particles = 3;
    }
    if (Viewport.Width >= 992) {
      particles = 4;
    }
    if (Viewport.Width >= 1200) {
      particles = 5;
    }
    console.log(particles, Viewport.Width);
  };

  const firstLevel = () => {
    let ret = [];
    pages.forEach((element) => {
      if (!ret.includes(element.url.split("/")[1])) {
        ret.push(element.url.split("/")[1]);
      }
    });
    return ret;
  };

  const secondLevel = (page) => {
    let ret = [];
    pages.forEach((element) => {
      if (element.url.split("/")[1] == page) {
        if (!ret.includes(element.url.split("/")[2])) {
          ret.push(element.url.split("/")[2]);
        }
      }
    });
    return ret;
  };

  const thirdLevel = (page) => {
    let ret = [];
    pages.forEach((element) => {
      if (element.url.split("/")[2] == page) {
        if (!ret.includes(element.url.split("/")[3])) {
          ret.push(element.url.split("/")[3]);
        }
      }
    });
    return ret;
  };

  const openSecondMenu = async (e) => {
    secondLevelRoutes = [];
    secondLevelRoutes = await secondLevel(e);
    return secondLevelRoutes;
  };

  const setActive = (path) => {
    window.location.replace(path);
    //router.redirect(path)
  };

  const getMenuTitle = (path) => {
    let title = "";
    pages.forEach((page) => {
      if (page.url == path) {
        title = page.title;
      }
    });
    return title;
  };

  const getEuro = (num) => {
    const euros = new Intl.NumberFormat(`sk-SK`, {
      currency: `EUR`,
      style: "currency",
    }).format(num);
    return euros;
  };

  const setOver = (id) => {
    showOver = id;
  };

  const filterAction = async (products) => {
    let retProducts;
    retProducts = await products.filter(
      (product) => product.tag.filter((tag) => tag == "AKCIA").length > 0,
    );
    console.log("filterAction: ", retProducts);
    return retProducts;
  };

  const filterNew = async (products) => {
    let retProducts;
    retProducts = await products.filter(
      (product) => product.tag.filter((tag) => tag == "NOVINKY").length > 0,
    );
    console.log("filterNew: ", retProducts);
    return retProducts;
  };

  const sortByOrder = async (products) => {
    let resProducts;
    resProducts = await products.sort((a, b) =>
      Number(a.order) > Number(b.order) ? 1 : -1,
    );
    resProducts = resProducts.slice(0, 10);
    console.log("sortByOrder: ", resProducts);
    return resProducts;
  };

  const leaveOver = () => {
    showOver = 0;
  };

  const handleNextClick = () => {
    carousel.goToNext();
  };
</script>

<Meta {metadata} />

<svelte:body on:viewportchanged={setParticles} />
<main class="bg-light">
  <div class="container bg-body">
    <div class="row mt-3">
      <div class="col-md-4">
        <div class="list-group" id="sideMenu">
          {#if firstLevelRoutes}
            {#each firstLevelRoutes as page}
              <a
                href={"/" + page}
                class="list-group-item list-group-item-action"
                on:click={setActive("/" + page)}
              >
              {#if !pages.some(p => p.url.endsWith(page) && p.icon)}
                {#if getMenuTitle("/" + page) == "VŠETKY PRODUKTY"}
                  <i class="bi bi-shop fs-2 mx-2"></i>
                {/if}
                {#if getMenuTitle("/" + page) == "AKCIOVÉ PRODUKTY"}
                  <i class="bi bi-tag fs-2 mx-2"></i>
                {/if}
                {#if getMenuTitle("/" + page) == "AKCIOVÉ ZOSTAVY"}
                  <i class="bi bi-bookmarks fs-2 mx-2"></i>
                {/if}
                {#if getMenuTitle("/" + page) == "VÝPREDAJ"}
                  <i class="bi bi-percent fs-2 mx-2"></i>
                {/if}
                {#if getMenuTitle("/" + page) == "OBKLADY A DLAŽBY"}
                  <i class="bi bi-bricks fs-2 mx-2"></i>
                {/if}
                {#if getMenuTitle("/" + page) == "POTRUBNÉ SYSTÉMY"}
                  <img
                    src="/icons/bugarsro-potrubne-systemy.png"
                    class="mx-2"
                    alt="POTRUBNÉ SYSTÉMY"
                    style="max-width: 2rem;"
                  />
                {/if}
                {#if getMenuTitle("/" + page) == "TLAKOVÉ A EXPANZNÉ NÁDOBY"}
                  <img
                    src="/icons/bugarsro-tlakove-nadoby.png"
                    class="mx-2"
                    alt="TLAKOVÉ A EXPANZNÉ NÁDOBY"
                    style="max-width: 2rem;"
                  />
                {/if}
                {#if getMenuTitle("/" + page) == "ÚPRAVA VODY"}
                  <i class="bi bi-moisture fs-2 mx-2"></i>
                {/if}
                {#if getMenuTitle("/" + page) == "KANALIZAČNÉ SYSTÉMY"}
                  <img
                    src="/icons/kanalizacne1.png"
                    class="mx-2"
                    alt="KANALIZAČNÉ SYSTÉMY"
                    style="max-width: 2rem;"
                  />
                {/if}

                {#if getMenuTitle("/" + page) == "ČERPADLÁ"}
                  <img
                    src="/icons/bugarsro-cerpadla-icon-01.png"
                    class="mx-2"
                    alt="ČERPADLÁ"
                    style="max-width: 2rem;"
                  />
                {/if}
                {#if getMenuTitle("/" + page) == "ČISTIACE PROSTRIEDKY"}
                  <img
                    src="/icons/bugarsro-cistiace-prostriedky.png"
                    class="mx-2"
                    alt="ČISTIACE PROSTRIEDKY"
                    style="max-width: 2rem;"
                  />
                {/if}

                {#if getMenuTitle("/" + page) == "KONVEKTORY"}
                  <img
                    src="/icons/bugarsro-konvektory-icon-01.png"
                    class="mx-2"
                    alt="KONVEKTORY"
                    style="max-width: 2rem;"
                  />
                {/if}
                {#if getMenuTitle("/" + page) == "KOTLY"}
                  <img
                    src="/icons/bugarsro-kotly-icon-01.png"
                    class="mx-2"
                    alt="KOTLY"
                    style="max-width: 2rem;"
                  />
                {/if}
                {#if getMenuTitle("/" + page) == "OHRIEVAČE"}
                  <img
                    src="/icons/bugarsro-ohrievace-icon-01.png"
                    class="mx-2"
                    alt="OHRIEVAČE"
                    style="max-width: 2rem;"
                  />
                {/if}
                {#if getMenuTitle("/" + page) == "RADIÁTORY"}
                  <img
                    src="/icons/bugarsro-radiatory-icon-01.png"
                    class="mx-2"
                    alt="RADIÁTORY"
                    style="max-width: 2rem;"
                  />
                {/if}
                {#if getMenuTitle("/" + page) == "SOLÁRNE SYSTÉMY"}
                  <img
                    src="/icons/bugarsro-solarne-systemy-02.png"
                    class="mx-2"
                    alt="SOLÁRNE SYSTÉMY"
                    style="max-width: 2rem;"
                  />
                {/if}
                {#if getMenuTitle("/" + page) == "STAVEBNÉ MATERIÁLY"}
                  <img
                    src="/icons/bugarsro-stavebne-materialy.png"
                    class="mx-2"
                    alt="STAVEBNÉ MATERIÁLY"
                    style="max-width: 2rem;"
                  />
                {/if}
                {#if getMenuTitle("/" + page) == "ŠACHTY"}
                  <i class="bi bi-octagon fs-2 mx-2"></i>
                {/if}
                {#if getMenuTitle("/" + page) == "TEPELNÉ ČERPADLÁ"}
                  <img
                    src="/icons/bugarsro-tepelne-cerpadla-01.png"
                    class="mx-2"
                    alt="TEPELNÉ ČERPADLÁ"
                    style="max-width: 2rem;"
                  />
                {/if}
                {#if getMenuTitle("/" + page) == "OVLÁDACIE JEDNOTKY"}
                  <img
                    src="/icons/bugarsro-regulatory-icon-01.png"
                    class="mx-2"
                    alt="OVLÁDACIE JEDNOTKY"
                    style="max-width: 2rem;"
                  />
                {/if}
                {#if getMenuTitle("/" + page) == "ZÁHRADNÝ PROGRAM"}
                  <img
                    src="/icons/bugarsro-zahradny-program-02.png"
                    class="mx-2"
                    alt="ZÁHRADNÝ PROGRAM"
                    style="max-width: 2rem;"
                  />
                {/if}
              {/if}

              {#if pages.some(p => p.url.endsWith(page) && p.icon)}
                  <i class={`bi ${pages.find(p => p.url.endsWith(page)).icon} fs-2 mx-2`}></i>
              {/if}

                {getMenuTitle("/" + page)}
              </a>
            {/each}
          {/if}
        </div>
        <!-- <div class="accordion" id="sideMenu">
          {#if firstLevelRoutes}
            {#each firstLevelRoutes as page}
            {#await openSecondMenu(page)}
              ...Loading
            {:then secondLevelRoutes}
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading{page}">
                  {#if secondLevelRoutes.length > 1}
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    aria-expanded="false"
                    data-bs-target="#collapse{page}"
                    aria-controls="collapse{page}"
                    data-bs-parent="#sideMenu"
                  >
                    {getMenuTitle("/" + page)}
                  </button>
                  {:else}
                  <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  aria-expanded="false"
                  data-bs-target="#collapse{page}"
                  aria-controls="collapse{page}"
                  data-bs-parent="#sideMenu"
                  on:click={setActive("/" + page)}
                >
                {getMenuTitle("/" + page)}
                </button>
                  {/if}

                </h2>
                <div
                  id="collapse{page}"
                  class="accordion-collapse collapse"
                  aria-labelledby="heading{page}"
                >
                  <div class="accordion-body">
                    <div>

                      {#each secondLevelRoutes as second}
                      <div class="my-3">
                        <a class="text-body link-menu"  on:click="{setActive('/'+page+'/'+second)}" href="{'/'+page+'/'+second}">
                          {getMenuTitle('/'+page+'/'+second)}
                        </a>
                      </div>
                      {/each}

                    </div>
                  </div>
                </div>
              </div>
              {/await}
            {/each}
          {/if}
        </div> -->
      </div>
      <div class="col-md-8">
        <!-- <div
          id="topCarousel"
          class="carousel slide carousel-fade"
          data-bs-ride="carousel"
        >
          <div class="carousel-indicators">
            <button
              type="button"
              data-bs-target="#topCarousel"
              data-bs-slide-to="0"
              class="active"
              aria-current="true"
              aria-label="Slide 1"
            />
            <button
              type="button"
              data-bs-target="#topCarousel"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            />
            <button
              type="button"
              data-bs-target="#topCarousel"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            />
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active" data-bs-interval="3000">
              <img src="/images/B1.jpg" class="d-block w-100" alt="b1" />
            </div>
            <div class="carousel-item" data-bs-interval="3000">
              <img src="/images/B4.jpg" class="d-block w-100" alt="b4" />
            </div>
            <div class="carousel-item" data-bs-interval="3000">
              <img src="/images/B3.jpg" class="d-block w-100" alt="b3" />
            </div>
            <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#topCarousel"
              data-bs-slide="prev"
            >
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#topCarousel"
              data-bs-slide="next"
            >
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div> -->

        {#if topGallery.images }
          <Carousel
          bind:this={banner}
          particlesToShow={1}
          particlesToScroll={1}
          dots={(true)}
          arrows={(false)}
          pauseOnFocus={(true)}
          autoplay={(true)}
          autoplayDuration={5000}
          >
            {#each topGallery.images as image }
            <div class="col">
              <img src={image.src} alt={image.alt} class="img-fluid"/>
            </div>
            {/each}
              
          </Carousel>
        {/if} 

        {#if servicesPages}
            <h4 class="mx-1 mt-md-5 m-3 text-center">SLUŽBY</h4>

            <div
              class="row text-center justify-content-xxl-between mt-3 mb-5 mx-2"
            >
            {#each servicesPages as servicePage}
              <div class="col-6 col-lg-4 p-md-0">
                <a
                  class="text-black text-decoration-none"
                  href={"/pages" + servicePage.url}
                  
                >
                  <img
                    class="img-fluid p-1"
                    src={servicePage.coverImage ? `/images/${servicePage.coverImage}` : brandBannerMapping[servicePage.url]}
                    alt="montazne-sluzby"
                    style="max-height: 250px;"
                  />
                  <p class="h5 mt-1">{servicePage.title}</p>
                </a>
              </div>
              {/each}
            </div>
        {/if}
      </div>
    </div>
    {#if particles > 0}
      <h4 class="mx-5 mt-md-5">TOP PRODUKTY</h4>
      <div>
        {#if products.length > 0}
          <Carousel
            bind:this={carousel}
            particlesToShow={particles}
            particlesToScroll={1}
            let:showPrevPage
            let:showNextPage
            dots={false}
          >
            <div
              slot="prev"
              on:click={showPrevPage}
              class="custom-arrow custom-arrow-prev position-relative"
              style="width: 2rem;"
            >
              <div
                class="bg-warning text-white position-absolute top-50 start-50 translate-middle pt-3 pb-3 rounded-pill"
              >
                <i class="bi bi-caret-left-fill" style="font-size: 1rem;" />
              </div>
            </div>

            {#each sortedByOrder as product}
              <div class="col p-3">
                <div class="shadow">
                  <a
                    class="text-decoration-none position-relative"
                    href="/product/{product.url}"
                  >
                    <div class="text-black p-3 pb-0">
                      <div
                        class="w-100 text-center position-relative overflow-hidden"
                      >
                        <div class="w-100">
                          <img
                            src={product.images[0]}
                            class="img-fluid"
                            style="height: 10rem;"
                            alt={product.name}
                          />
                        </div>

                        <h5 class="position-absolute top-0 start-0">
                          {#if product.action}
                            <span
                              class="badge rounded-pill bg-primary text-white"
                            >
                              <i
                                class="bi bi-percent"
                                style="font-size: .7rem;"
                              />
                              Lepšia cena
                            </span>
                          {/if}
                          <span>&nbsp;</span>
                        </h5>
                      </div>
                    </div>
                    <div class="w-100 border-bottom" />
                    <div class="row text-black p-3 pt-1 pb-1">
                      <div class="col h-100">
                        <p class="text-truncate">{product.name}</p>
                        {#if product.action == true}
                          <div class="float-end mb-3">
                            <span
                              class="text-black-50 text-decoration-line-through"
                              >{getEuro(product.price)}</span
                            >
                            <span class="">{getEuro(product.actionprice)}</span>
                          </div>
                        {:else}
                          <p class="float-end">{getEuro(product.price)}</p>
                        {/if}
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            {/each}

            <div
              slot="next"
              on:click={showNextPage}
              class="custom-arrow custom-arrow-next position-relative"
              style="width: 2rem;"
            >
              <div
                class="bg-warning text-white position-absolute top-50 start-50 translate-middle pt-3 pb-3 rounded-pill"
              >
                <i class="bi bi-caret-right-fill" style="font-size: 1rem;" />
              </div>
            </div>
          </Carousel>
        {/if}
      </div>

      <h4 class="mx-5">NOVINKY</h4>
      <div>
        {#if products.length > 0}
          <Carousel
            bind:this={carousel}
            particlesToShow={particles}
            particlesToScroll={1}
            let:showPrevPage
            let:showNextPage
            dots={false}
          >
            <div
              slot="prev"
              on:click={showPrevPage}
              class="custom-arrow custom-arrow-prev position-relative"
              style="width: 2rem;"
            >
              <div
                class="bg-warning text-white position-absolute top-50 start-50 translate-middle pt-3 pb-3 rounded-pill"
              >
                <i class="bi bi-caret-left-fill" style="font-size: 1rem;" />
              </div>
            </div>

            {#each filteredNew as product}
              <div class="col p-3">
                <div class="shadow">
                  <a
                    class="text-decoration-none position-relative"
                    href="/product/{product.url}"
                  >
                    <div class="text-black p-3 pb-0">
                      <div
                        class="w-100 text-center position-relative overflow-hidden"
                      >
                        <div class="w-100">
                          <img
                            src={product.images[0]}
                            class="img-fluid"
                            style="height: 10rem;"
                            alt={product.name}
                          />
                        </div>

                        <div
                          class="position-absolute top-0 start-0 fs-5 text-start"
                        >
                          {#if product.action}
                            <p
                              class="badge rounded-pill bg-primary text-white mb-0"
                            >
                              <i class="bi bi-percent" style="font-size: .7rem;"
                              ></i>
                              Lepšia cena
                            </p>
                            <br />
                          {/if}
                          {#if product.tag.filter((tag) => tag == "Na sklade").length > 0}
                            <p
                              class="badge rounded-pill bg-success text-white mb-0"
                            >
                              <i class="bi bi-shop" style="font-size: .7rem;"
                              ></i>
                              Na sklade
                            </p>
                            <br />
                          {/if}
                        </div>
                      </div>
                    </div>
                    <div class="w-100 border-bottom" />
                    <div class="row text-black p-3 pt-1 pb-1">
                      <div class="col h-100">
                        <p class="text-truncate">{product.name}</p>
                        {#if product.action == true}
                          <div class="float-end mb-3">
                            <span
                              class="text-black-50 text-decoration-line-through"
                              >{getEuro(product.price)}</span
                            >
                            <span class="">{getEuro(product.actionprice)}</span>
                          </div>
                        {:else}
                          <p class="float-end">{getEuro(product.price)}</p>
                        {/if}
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            {/each}

            <div
              slot="next"
              on:click={showNextPage}
              class="custom-arrow custom-arrow-next position-relative"
              style="width: 2rem;"
            >
              <div
                class="bg-warning text-white position-absolute top-50 start-50 translate-middle pt-3 pb-3 rounded-pill"
              >
                <i class="bi bi-caret-right-fill" style="font-size: 1rem;" />
              </div>
            </div>
          </Carousel>
        {/if}
      </div>

      <h4 class="mx-5">AKCIE</h4>
      <div>
        {#if products.length > 0}
          <Carousel
            bind:this={carousel}
            particlesToShow={particles}
            particlesToScroll={1}
            let:showPrevPage
            let:showNextPage
            dots={false}
          >
            <div
              slot="prev"
              on:click={showPrevPage}
              class="custom-arrow custom-arrow-prev position-relative"
              style="width: 2rem;"
            >
              <div
                class="bg-warning text-white position-absolute top-50 start-50 translate-middle pt-3 pb-3 rounded-pill"
              >
                <i class="bi bi-caret-left-fill" style="font-size: 1rem;" />
              </div>
            </div>

            {#each filteredAction as product}
              <div class="col p-3">
                <div class="shadow">
                  <a
                    class="text-decoration-none position-relative"
                    href="/product/{product.url}"
                  >
                    <div class="text-black p-3 pb-0">
                      <div
                        class="w-100 text-center position-relative overflow-hidden"
                      >
                        <div class="w-100">
                          <img
                            src={product.images[0]}
                            class="img-fluid"
                            style="height: 10rem;"
                            alt={product.name}
                          />
                        </div>

                        <h5 class="position-absolute top-0 start-0">
                          {#if product.action}
                            <span
                              class="badge rounded-pill bg-primary text-white"
                            >
                              <i
                                class="bi bi-percent"
                                style="font-size: .7rem;"
                              />
                              Lepšia cena
                            </span>
                          {/if}
                          <span>&nbsp;</span>
                        </h5>
                      </div>
                    </div>
                    <div class="w-100 border-bottom" />
                    <div class="row text-black p-3 pt-1 pb-1">
                      <div class="col h-100">
                        <p class="text-truncate">{product.name}</p>
                        {#if product.action == true}
                          <div class="float-end mb-3">
                            <span
                              class="text-black-50 text-decoration-line-through"
                              >{getEuro(product.price)}</span
                            >
                            <span class="">{getEuro(product.actionprice)}</span>
                          </div>
                        {:else}
                          <p class="float-end">{getEuro(product.price)}</p>
                        {/if}
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            {/each}

            <div
              slot="next"
              on:click={showNextPage}
              class="custom-arrow custom-arrow-next position-relative"
              style="width: 2rem;"
            >
              <div
                class="bg-warning text-white position-absolute top-50 start-50 translate-middle pt-3 pb-3 rounded-pill"
              >
                <i class="bi bi-caret-right-fill" style="font-size: 1rem;" />
              </div>
            </div>
          </Carousel>
        {/if}
      </div>

      <div class="d-flex justify-content-center my-5">
        {#if products.length > 0}
          <div class="justify-content-start w-100">
            <h4 class="mx-5">PARTNERI</h4>
            <Carousel
              bind:this={carousel}
              particlesToShow={particles * 2}
              particlesToScroll={1}
              dots={false}
              let:showPrevPage
              let:showNextPage
            >
              <div
                slot="prev"
                on:click={showPrevPage}
                class="custom-arrow custom-arrow-prev position-relative"
                style="width: 2rem;"
              >
                <div
                  class="bg-warning text-white position-absolute top-50 start-50 translate-middle pt-3 pb-3 rounded-pill"
                >
                  <i class="bi bi-caret-left-fill" style="font-size: 1rem;" />
                </div>
              </div>

              <div class="col p-3">
                <a href="/eshop?filter=PROTHERM">
                  <img
                    src="/brand/partners/protherm-logo.jpeg"
                    alt="protherm"
                  />
                </a>
              </div>
              <div class="col p-3">
                <a href="/eshop?filter=KORADO">
                  <img src="/brand/partners/korado-logo.jpeg" alt="korado" />
                </a>
              </div>
              <div class="col p-3">
                <a href="/eshop?filter=AIRFEL">
                  <img src="/brand/partners/airfel_logo.png" alt="airfel" />
                </a>
              </div>
              <div class="col p-3">
                <a href="/eshop?filter=ELÍZ">
                  <img src="/brand/partners/logo_eliz.gif" alt="eliz" />
                </a>
              </div>
              <div class="col p-3">
                <a href="/eshop?filter=STELRAD">
                  <img src="/brand/partners/sterad_logo.png" alt="stelrad" />
                </a>
              </div>
              <div class="col p-3">
                <a href="/eshop?filter=THERMAL TREND">
                  <img
                    src="/brand/partners/thermal-trend.png"
                    alt="thermal-trend"
                  />
                </a>
              </div>
              <!-- <div class="col p-3">
              <a href="/eshop?filter=JIKA">
                <img src="/brand/partners/jika.png" alt="jika" />
              </a>
            </div> -->
              <!-- <div class="col p-3">
              <a href="/eshop?filter=CHATE">
                <img src="/brand/partners/logo_chate.jpg" alt="chate" />
              </a>
            </div> -->
              <!-- <div class="col p-3">
              <a href="/eshop?filter=LAUFEN">
                <img src="/brand/partners/laufen.png" alt="laufen" />
              </a>
            </div> -->
              <!-- <div class="col p-3">
              <a href="/eshop?filter=KYOKUSHIN">
                <img src="/brand/partners/logo_kyokushin.jpg" alt="kyokushin" />
              </a>
            </div> -->
              <!-- <div class="col p-3">
              <a href="/eshop?filter=GROHE">
                <img src="/brand/partners/grohe.png" alt="grohe" />
              </a>
            </div> -->
              <!-- <div class="col p-3">
              <a href="/eshop?filter=STK">
                <img src="/brand/partners/logo_stk.gif" alt="stk" />
              </a>
            </div> -->
              <!-- <div class="col p-3">
              <a href="/eshop?filter=HANSGROHE">
                <img
                  src="/brand/partners/hansgrohe_logo1-629x136.png"
                  alt="hansgrohe"
                />
              </a>
            </div> -->
              <!-- <div class="col p-3">
              <a href="/eshop?filter=ROYAL">
                <img src="/brand/partners/logo-royal.jpg" alt="royal" />
              </a>
            </div> -->
              <!-- <div class="col p-3">
              <a href="/eshop?filter=RAVAK">
                <img src="/brand/partners/ravak.jpg" alt="ravak" />
              </a>
            </div> -->
              <div
                slot="next"
                on:click={showNextPage}
                class="custom-arrow custom-arrow-next position-relative"
                style="width: 2rem;"
              >
                <div
                  class="bg-warning text-white position-absolute top-50 start-50 translate-middle pt-3 pb-3 rounded-pill"
                >
                  <i class="bi bi-caret-right-fill" style="font-size: 1rem;" />
                </div>
              </div>
            </Carousel>
          </div>
        {/if}
      </div>
    {/if}
  </div>
  <GdprBanner
    cookieName={options.cookieName}
    heading={options.heading}
    description={options.description}
    showEditIcon={options.showEditIcon}
    acceptLabel={options.acceptLabel}
    rejectLabel={options.rejectLabel}
    settingsLabel={options.settingsLabel}
    closeLabel={options.closeLabel}
    editLabel={options.editLabel}
    choices={options.choices}
    on:analytics={initAnalytics}
  />
</main>

<style>
  .container {
    max-width: 1680px !important;
  }
  /* .link-menu{
		text-decoration: none;
	}
	.link-menu:hover{
		text-decoration: underline;
	} */
</style>
