<script>
  import { onMount } from "svelte";
  import { onDestroy } from "svelte";
  import { order } from "./storage/orderStore.js";

  let orderStore;
  let orderPayment = {
        "id": 0,
        "user": "",
        "products": [],
        "state": "uncopleted",
        "createdAt": "",
        "vs": "",
        "address": {
          "name":"",
          "lastname":"",
          "compannyname":"",
          "street":"",
          "zip":"",
          "city":"",
        },
        "invoiceaddress": {
          "name":"",
          "lastname":"",
          "compannyname":"",
          "street":"",
          "zip":"",
          "city":"",
        },
        "ico":"",
        "dic":"",
        "icdic":"",
        "note":"",
        "email":"",
        "msisdn":"",
        "sum":0,
        "qrimage":"",
        "isShipping":true
      };

  order.subscribe((orderValue) => {
    orderStore = orderValue;
  });

  const unsubscribe = order.subscribe((orderValue) => {
    orderStore = orderValue;
  });

  onDestroy(unsubscribe);

  const emptyBasket = () => {
    order.set({
        "id": 0,
        "user": "",
        "products": [],
        "state": "uncopleted",
        "createdAt": "",
        "vs": "",
        "address": {
          "name":"",
          "lastname":"",
          "compannyname":"",
          "street":"",
          "zip":"",
          "city":"",
        },
        "invoiceaddress": {
          "name":"",
          "lastname":"",
          "compannyname":"",
          "street":"",
          "zip":"",
          "city":"",
        },
        "ico":"",
        "dic":"",
        "icdic":"",
        "note":"",
        "email":"",
        "msisdn":"",
        "sum":0,
        "qrimage":"",
        "isShipping":true
      });
      console.log("emptyBasket");
  };

  onMount(async () => {
    orderPayment = orderStore
    console.log(orderPayment);
    emptyBasket()
    backToTop();
  });

  const backToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  const getEuro = (num) => {
    const euros = new Intl.NumberFormat(`sk-SK`, {
      currency: `EUR`,
      style: "currency",
    }).format(num);
    return euros;
  };
</script>

<main class="bg-light">
  <div class="container bg-body">
    <h3 class="mt-3">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <i class="bi bi-house-fill" />
            
          </li>
          <li class="breadcrumb-item">Dodacie údaje</li>
          <li class="breadcrumb-item">Doprava a platba</li>
          <li class="breadcrumb-item active" aria-current="page">
            Potvrdenie objednávky
          </li>
        </ol>
      </nav>
    </h3>

    <div class="row justify-content-md-center mt-3 mb-5">
      
      {#if orderPayment.pkType !== "IC"}
      <div class="col-md-4 bg-dark text-white p-4">
        <h3>Zhrnutie objednávky</h3>
        <p class=" mb-0">
          Spolu cena bez DPH: {getEuro(orderPayment.sum / 1.2)}
        </p>
        <p>
          Spolu cena s DPH : {getEuro(orderPayment.sum)}
        </p>
        <hr />
        <h3 class=" mb-2">Platobné údaje</h3>
        <p class=" mb-0">IBAN: SK57 1100 0000 0029 4411 2826</p>
        <p class=" mb-0">Variabilný symbol: {orderPayment.vs}</p>
        <p class=" mb-0">Spolu cena bez DPH: {getEuro(orderPayment.sum / 1.2)}</p>
        <p class=" mb-0">Spolu cena s DPH : {getEuro(orderPayment.sum)}</p>
        <hr />
        <div
          class="row justify-content-md-center align-items-center text-center"
        >
          <div class="col-md-auto">
            <h3>Naskenuj bankovou mobilnou aplikáciou</h3>
            <div>
              {#if orderPayment.qrimage != ""}
                <img class="img-fluid" src={orderPayment.qrimage} alt="qrcode" />
              {/if}
            </div>
          </div>
        </div>
      </div>
      {/if}

      <div class="col-md-8 text-center p-5">
        <h1 class="mb-5">Ďakujeme za vašu objednávku.</h1>
        <a href="/" class="btn btn-primary btn-lg">Späť do obchodu</a>
        <!-- 
                {#each orderStore.products as product, index}
                  <div
                    class="row align-items-center text-center text-md-start  shadow m-3 p-2"
                  >
                    <div class="col-md-auto">
                      {#if product.images[0]}
                        <img
                          style="height: 10rem;"
                          class="img-thumbnail"
                          src={product.images[0]}
                          alt={product.name}
                        />
                      {/if}

                    </div>
                    <div class="col-md">

                      <h3>{product.name}</h3>
                      <p class="mb-1">Cena s DPH</p>
                      {#if product.action}
                        <h3 class="">{getEuro(product.actionprice)}</h3>
                      {:else}
                      <h3 class="">{getEuro(product.price)}</h3>
                      {/if}

                    </div>
                    <div class="col-md-auto ">
                    </div>
                  </div>
                {/each} -->
      </div>

    </div>
  </div>
</main>

<style>
  .container {
    max-width: 1680px !important;
  }
  @import "/css/font.css";
  @import "/css/bs-custom.css";
  @import "/css/bootstrap-icons.css";
</style>
