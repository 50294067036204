<script>
	var disabled = true;
	var showProgress = false;
</script>

<main class="text-center align-middle">
	<div style="width: 28rem;">
		<div>
			<h2 class="mt-1">Deaktivácia účtu</h2>
		</div>
		<div class="mt-1">
			Nový účet? <span><a href="/registracia">Registácia</a></span>
		</div>

		<div class="form-floating m-3 mx-5">
			<input
				type="email"
				class="form-control"
				id="floatingInput"
				placeholder="name@example.com"
			/>
			<label for="floatingInput">Emailová adresa</label>
		</div>
		<div class="form-floating  m-3 mx-5">
			<input
				type="password"
				class="form-control"
				id="floatingPassword"
				placeholder="Password"
			/>
			<label for="floatingPassword">Heslo</label>
		</div>


		<div class="my-4 mx-5 d-grid d-md-block">
			<button {disabled} class="btn-lg btn-primary">
				<span class="spinner-border spinner-border-sm" role="status" />
				Deaktivovať</button
			>
		</div>
	</div>
</main>

<style>
	@import "/css/font.css";
	@import "/css/bs-custom.css";
</style>
