<script>
    import { onMount } from 'svelte';
    import router from "page";
    import { order } from './storage/orderStore.js';

    onMount(async () => {
		// const res =  await fetch('/order-api/order');
		// order = await res.json();
	});

    let quantity = 1;

    let increment = (index) => {
        order.update(order => {
            let newOrder = order;
            if (newOrder.products[index].quantity < 9999){
                newOrder.products[index].quantity = newOrder.products[index].quantity + 1
            }
            newOrder.sum = 0;
            newOrder.products.forEach((product) => {
                newOrder.sum = newOrder.sum + (Number(product.price) * Number(product.quantity));
		    }) 
            return newOrder
        })
    };
    let decrement = (index) => {
        order.update(order => {
            let newOrder = order;
            if (newOrder.products[index].quantity > 1){
                newOrder.products[index].quantity = newOrder.products[index].quantity - 1
            }
            newOrder.sum = 0;
            newOrder.products.forEach((product) => {
                newOrder.sum = newOrder.sum + (Number(product.price) * Number(product.quantity));
		    }) 
            return newOrder
        })
    };
    

    const removeProduct = (index) => {
        order.update(order => {
            let newOrder = order;
            newOrder.products.splice(index, 1);
            newOrder.sum = 0;
            newOrder.products.forEach((product) => {
                newOrder.sum = newOrder.sum + (product.price * product.quantity);
		    }) 
            return newOrder
        })
    }
    

    const emptyBasket = ()=>{
        order.update(order => {
            let newOrder = order;
            newOrder.products = []
            newOrder.sum = 0
            return newOrder
        })
    }

    const getEuro = (num)=>{
        const euros = new Intl.NumberFormat(`sk-SK`, {
            currency: `EUR`,
            style: 'currency',
        }).format(num);
        return euros
    }

</script>
<main class="bg-light">
    <div class="container bg-body">
        {#if $order.products.length == 0}
        <div class="container text-center mt-5 mb-5">
            <h3>Váš košík je prázdny.</h3>
            <p>Vyberte si z našej ponuky</p>
            <a href="/" class="btn btn-primary">Pozrieť ponuku</a>
         </div>
             
        {:else}
        
        <div class="row">
            <div class="col-md-9">
                <div class="row align-items-center m-3 p-2">
                    <div class="col-md">
                        <h3 class="m-0 p-0">Košík</h3>
                    </div>
                    <div class="col-md">
                        <a class="float-md-end" href="/basket" on:click="{emptyBasket}">Vyprázdniť košík</a>
                    </div>
                </div>
                {#each $order.products as product , index }
                    <div class="row align-items-center text-center text-md-start  shadow m-3 p-2">
                        <div class="col-md-auto">
                            <img style="height: 10rem;" class="img-thumbnail" src="{product.images[0]}" alt="{product.name}">
                        </div>
                        <div class="col-md">
                            <h3>{product.name}</h3>
                            <p class="mb-1">
                                Množstvo:
                            </p>
                            <div style="width: 10rem;">
                                <div class="input-group mb-3" >
                                    <button class="btn btn-primary" type="button">
                                        <i class="bi bi-dash" style="font-size: 1rem;" on:click={() =>decrement(index)}></i>
                                    </button>
                                    <input type="number" class="form-control" bind:value="{$order.products[index].quantity}" min="0" max="9999">
                                    <button class="btn btn-primary" type="button" on:click={() =>increment(index)}>
                                        <i class="bi bi-plus" style="font-size: 1rem;"></i>
                                    </button>
                                </div>
                            </div>
                            <p class="mb-1">Cena s DPH</p>
                            <h3 class="">{getEuro(product.actionprice)}</h3>
                            
                        </div>
                        <div class="col-md-auto ">
                            <button class="btn btn-primary btn-lg" on:click={() => removeProduct(index)} >
                                <i class="bi bi-trash"></i>
                            </button>
                        </div>
                    </div>
                {/each}
            </div>
            <div class="col-md-3 bg-dark text-white p-4">
                <h3>Zhrnutie objednávky</h3>
                <p class=" mb-0">
                    Spolu cena bez DPH: {getEuro($order.sum/1.20)}
                </p>
                <p>
                    Spolu cena s DPH : {getEuro($order.sum)}
                </p>
                <hr/>
                <div class="d-grid gap-2">
                    <a class="btn btn-primary btn-lg" href="/checkout/information">Pokračovať</a>
                </div>
            </div>
        </div>
        
        {/if}
    </div>

</main>

<style>
    .container{
        max-width: 1680px !important;
    }
    @import "/css/font.css";
	@import "/css/bs-custom.css";
	@import "/css/bootstrap-icons.css";
</style>