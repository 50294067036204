<script>
  import { onMount } from "svelte";
  import { onDestroy } from 'svelte';
  import { order } from "./storage/orderStore.js";

  let isLoged = false;
  let token = "";
  let email = "";
  let ico = "";
  let dic = "";
  let icdph = "";
  let zones = [];
  let isZIP = false;
  let isEmail = false;
  let showProgress = false;
  let orderStore;

  order.subscribe(orderValue => {
      orderStore = orderValue;
	  });

    const unsubscribe = order.subscribe(orderValue => {
		  orderStore = orderValue;
	  });

	  onDestroy(unsubscribe);

  function addEmail(email) {
		order.update(order => {
            let newOrder =  order;
            newOrder.email = email;
            return newOrder
        });
	}
  const checkIfAvailable = (zip)=>{
    return( zones.filter(codes => codes.ZipCode == zip.split(" ").join("")).length > 0 )
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  } ;

  const checkIfBasketEmpty = (order)=>{
    return( order.products.length == 0 )
  }

  const fixZIP = (zip)=>{
    orderStore.address.zip = zip.replace(/\s/g, '');
  }

  
  $: fixZIP(orderStore.address.zip)
  $: isZIP = checkIfAvailable(orderStore.address.zip)
  $: isEmail = validateEmail(orderStore.email)
  $: isBasketEmpty = checkIfBasketEmpty(orderStore)
  
  var user = {
		email: "",
		password: ""
	};
	


  const getProfile = async (token,exdays)=>{
		const options = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"token": token
			},
		};
		let res = await fetch(`/customer-api/profile`, options)
			.then((res) => res.json())
			.then((data) => {
				setCookie("email", data.email, exdays);
				addEmail(data.email)
			})
			.catch((err) => {
				console.log("getProfile: Email not set", err);
			});
	}

	const getCookie = (cname) => {
  		let name = cname + "=";
  		let decodedCookie = decodeURIComponent(document.cookie);
  		let ca = decodedCookie.split(';');
  		for(let i = 0; i <ca.length; i++) {
    		let c = ca[i];
    		while (c.charAt(0) == ' ') {
      			c = c.substring(1);
    		}
    		if (c.indexOf(name) == 0) {
      			return c.substring(name.length, c.length);
    		}
  		}
  		return "";
	}

  const setCookie = (cname,cvalue,exdays) => {
  		const d = new Date();
  		d.setTime(d.getTime() + (exdays*24*60*60*1000));
  		let expires = "expires=" + d.toUTCString();
  		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}

  const loginRequest = async () => {
		showProgress = true;
		const options = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"Authorization": window.btoa(`${user.email}:${user.password}`)
			},
		};
		try {
			let res = await fetch(`/customer-api/auth`, options)
				.then((res) => res.json())
				.then((data) => {
					showProgress = false;
					if (data.message == 'unautorized'){
						alert("Unauthorized");
					}else{
						var exdays = 1;
					setCookie("token", data.token, exdays);
          getProfile(data.token, exdays);
					isLoged = true;
					}

				})
				.catch((err) => {
					showProgress = false;
					alert("Unauthorized", err);
				});
		} catch (err) {
			showProgress = false;
			console.log(err);
		}
	};

  onMount(async () => {
		token = getCookie("token");
  	if (token != "") {
			//formVal.email = "Prihlasené";
			isLoged = true;
      isLoged = isLoged;
 		}
    email = getCookie("email");
    if (email != "") {
      addEmail(email)
 		}

    const resZone = await fetch(`/shipping-api/zone`);
    zones = await resZone.json();
  });

  let invoiceAddressCheck = true;
  let newAccount = false;

  const removeProduct = (index) => {
    order.update((order) => {
      let newOrder = order;
      newOrder.products.splice(index, 1);
      newOrder.sum = 0;
      newOrder.products.forEach((product) => {
        newOrder.sum = newOrder.sum + product.price * product.quantity;
      });
      return newOrder;
    });
  };

  const emptyBasket = () => {
    order.update((order) => {
      let newOrder = order;
      newOrder.products = [];
      newOrder.sum = 0;
      return newOrder;
    });
  };
  const getEuro = (num) => {
    const euros = new Intl.NumberFormat(`sk-SK`, {
      currency: `EUR`,
      style: "currency",
    }).format(num);
    return euros;
  };

  const printOrder = ()=>{
    console.log(order.toString());
  }
</script>

<main class="bg-light">
  <div class="container bg-body">
    <h3 class="mt-3">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="/">
              <i class="bi bi-house-fill" />
              </a
            >
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            <a href="/order/information">Dodacie údaje</a>
          </li>
          <li class="breadcrumb-item">Doprava a platba</li>
          <li class="breadcrumb-item">Potvrdenie objednávky</li>
        </ol>
      </nav>
    </h3>

    <div class="row mb-5">
      <div class="col-md-4 bg-dark text-white p-4">
        <h3>Zákaznícke informácie</h3>
        <form>
          
          {#if !isLoged}
          <h5>Prihlásenie</h5>
          <div class="form-floating mb-3 text-black">
            <input
              type="email"
              class="form-control"
              id="floatingInput"
              placeholder="name@example.com"
              bind:value="{user.email}"
            /> <label for="floatingInput">Emailová adresa</label>
          </div>

          <div class="form-floating mb-3 text-black">
            <input
              type="password"
              class="form-control"
              id="floatingPassword"
              placeholder="Password"
              bind:value="{user.password}"
            /> <label for="floatingPassword">Heslo</label>
          </div>

          <div class="row mb-4">
            <div class="col">
              <button class="btn btn-primary btn-lg">Zabudnuté heslo</button>
            </div>
            <div class="col">
              <button class="btn btn-primary btn-lg float-end" on:click|preventDefault={loginRequest}>
                {#if showProgress}
                <span  class="spinner-border spinner-border-sm" role="status" />
                {/if}
                Prihlásiť sa</button>
            </div>
          </div>
          {/if}

          
          <h5>Adresa pre doručenie</h5>

          <div class="row">
            <div class="col-md-5">
              <div class="form-floating mb-3 text-black">
                <input
                  type="text"
                  class="form-control"
                  id="nameInput"
                  placeholder="Meno"
                  bind:value="{$order.address.name}"
                /> <label for="nameInput">Meno</label>
              </div>
            </div>
            <div class="col-md-7">
              <div class="form-floating mb-3 text-black">
                <input
                  type="text"
                  class="form-control"
                  id="lastnameInput"
                  placeholder="Priezvisko"
                  bind:value="{$order.address.lastname}"
                /> <label for="lastnameInput">Priezvisko</label>
                <div id="lastnameInput" class="invalid-feedback">
                  Prosím zadaj priezvisko.
                </div>
              </div>
            </div>
          </div>

          <div class="form-floating mb-3 text-black">
            <input
              type="text"
              class="form-control"
              id="firmInput"
              placeholder="Firma"
              bind:value="{$order.address.compannyname}"
            /> <label for="firmInput">Firma</label>
          </div>

          <div class="form-floating mb-3 text-black">
            <input
              type="text"
              class="form-control"
              id="streetInput"
              placeholder="Ulica - Číslo"
              bind:value="{$order.address.street}"
            /> <label for="streetInput">Ulica - Číslo</label>
          </div>

          <div class="row">
            <div class="col-md-3">
              <div class="form-floating mb-3 text-black">
                {#if isZIP}
                <input
                type="text"
                class="form-control"
                id="zipInput"
                placeholder="PSČ"
                bind:value="{$order.address.zip}"
              /> <label for="zipInput">PSČ</label>
                {:else}
                <input
                type="text"
                class="form-control is-invalid"
                id="zipInput"
                placeholder="PSČ"
                bind:value="{$order.address.zip}"
              /> <label for="zipInput">PSČ</label>
              <div id="zipInputFeedback" class="invalid-feedback">
                Zadaj správne PSČ.
              </div>
                {/if}

              </div>
            </div>
            <div class="col-md-9">
              <div class="form-floating mb-3 text-black">
                <input
                  type="text"
                  class="form-control"
                  id="cityInput"
                  placeholder="Mesto"
                  bind:value="{$order.address.city}"
                /> <label for="cityInput">Mesto</label>
              </div>
            </div>
          </div>

          <div class="form-check mb-3">
            <input
              class="form-check-input"
              type="checkbox"
              id="flexCheckDefault"
              bind:checked={invoiceAddressCheck}
            />
            <label class="form-check-label" for="flexCheckDefault">
              Faktúračná adresa je rovnaká
            </label>
          </div>

          <!-- invoice address -->
          {#if !invoiceAddressCheck}
          <div class="mb-3">
            <h5>Faktúračná adresa</h5>

            <div class="row">
              <div class="col-md-5">
                <div class="form-floating mb-3 text-black">
                  <input
                    type="text"
                    class="form-control"
                    id="invoiceNameInput"
                    placeholder="Meno"
                    bind:value="{$order.invoiceaddress.name}"
                  /> <label for="invoiceNameInput">Meno</label>
                </div>
              </div>
              <div class="col-md-7">
                <div class="form-floating mb-3 text-black">
                  <input
                    type="text"
                    class="form-control"
                    id="invoiceLastnameInput"
                    placeholder="Priezvisko"
                    bind:value="{$order.invoiceaddress.lastname}"
                  /> <label for="invoiceLastnameInput">Priezvisko</label>
                </div>
              </div>
            </div>

            <div class="form-floating mb-3 text-black">
              <input
                type="text"
                class="form-control"
                id="invoiceFirmInput"
                placeholder="Firma"
                bind:value="{$order.invoiceaddress.compannyname}"
              /> <label for="invoiceFirmInput">Firma</label>
            </div>

            <div class="form-floating mb-3 text-black">
              <input
                type="text"
                class="form-control"
                id="invoiceStreetInput"
                placeholder="Ulica - Číslo"
                bind:value="{$order.invoiceaddress.street}"
              /> <label for="invoiceStreetInput">Ulica - Číslo</label>
            </div>

            <div class="row">
              <div class="col-md-3">
                <div class="form-floating mb-3 text-black">
                  <input
                    type="text"
                    class="form-control"
                    id="invoiceZipInput"
                    placeholder="PSČ"
                    bind:value="{$order.invoiceaddress.zip}"
                  /> <label for="invoiceZipInput">PSČ</label>
                </div>
              </div>
              <div class="col-md-9">
                <div class="form-floating mb-3 text-black">
                  <input
                    type="text"
                    class="form-control"
                    id="invoiceCityInput"
                    placeholder="Mesto"
                    bind:value="{$order.invoiceaddress.city}"
                  /> <label for="invoiceCityInput">Mesto</label>
                </div>
              </div>
            </div>
          </div>  
          {/if}

          <div class="form-floating mb-3 text-black">
            <input
              type="email"
              class="form-control {isEmail ? '' : 'is-invalid'}"
              id="newEmailInput"
              placeholder="name@example.com"
              bind:value="{$order.email}"
            />
            <label for="newEmailInput">Emailová adresa</label>
            {#if !isEmail}
              <div id="emailInputFeedback" class="invalid-feedback">
                Zadaj Email.
              </div>
            {/if}
          </div>
          



          <div class="form-floating mb-3 text-black">
            <input
              type="tel"
              class="form-control"
              id="telInput"
              placeholder="Telefónne číslo"
              bind:value="{$order.msisdn}"
            /> <label for="telInput">Telefónne číslo</label>
          </div>
          <div class="form-floating mb-3 text-black">
            <input
              type="text"
              class="form-control"
              id="icoInput"
              placeholder="IČO"
              bind:value="{$order.ico}"
            /> <label for="icoInput">IČO</label>
          </div>
          <div class="form-floating mb-3 text-black">
            <input
              type="text"
              class="form-control"
              id="dicInput"
              placeholder="DIČ"
              bind:value="{$order.dic}"
            /> <label for="dicInput">DIČ</label>
          </div>
          <div class="form-floating mb-3 text-black">
            <input
              type="text"
              class="form-control"
              id="icdicInput"
              placeholder="IČDIČ"
              bind:value="{$order.icdic}"
            /> <label for="icdicInput">IČ DPH</label>
          </div>
          <div class="form-floating mb-5 text-black">
            <textarea class="form-control" placeholder="Poznámka k objednávke" id="noteInput" style="height: 100px"
            bind:value="{$order.note}"
            ></textarea>
            <label for="noteInput">Poznámka k objednávke</label>
          </div>

          {#if !isLoged}
          <h5>Zadaj heslo pre nový účet</h5>
          <div class="row align-items-center mb-5">
            <div class="col-md">
              <div class="form-floating mb-3 text-black">
                <input
                  type="password"
                  class="form-control"
                  id="newPassword"
                  placeholder="Password"
                /> <label for="newPassword">Heslo</label>
              </div>
            </div>
            <div class="col-md">
              <div class="form-check mb-3">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="newAccount"
                  bind:checked={newAccount}
                />
                <label class="form-check-label" for="newAccount">
                  Vytvoriť nový účet.
                </label>
              </div>
            </div>
          </div>
          {/if}

          <div class="row">
            <div class="col">
              <a href="/" class="btn btn-primary btn-lg">Späť nakupovať</a>
            </div>
            <div class="col">
              {#if isZIP && isEmail}
                {#if !isBasketEmpty}
                <div class="float-end">
                  <a href="/checkout/shipping" class="btn btn-primary btn-lg " on:click="{printOrder}"
                  >Pokračovať</a
                  >
    
                </div>

                
                {:else}
                <div class="float-end">
                  <a href="/checkout/shipping" class="btn btn-primary btn-lg  disabled"
                  >Pokračovať</a
                >
   
                </div>

                {/if}

              {:else}
              <div class="float-end">
                <a href="/checkout/shipping" class="btn btn-primary btn-lg  disabled"
                >Pokračovať</a
              >
              </div>

              {/if}

            </div>
          </div>
        </form>
      </div>
      <div class="col-md-8">
        {#if $order.products.length == 0}
        <div class="container text-center mt-5 mb-5">
            <h3>Váš košík je prázdny.</h3>
            <p>Vyberte si z našej ponuky</p>
            <a href="/" class="btn btn-primary">Pozrieť ponuku</a>
         </div>
             
        {:else}
        <div class="row align-items-center m-3 p-2">
          <div class="col-md">
            <h3 class="m-0 p-0">Súhrn ojednávky</h3>
          </div>
          <!-- <div class="col-md">
            <a class="float-md-end" href="/basket" on:click={emptyBasket}
              >Vyprázdniť košík</a
            >
          </div> -->
        </div>
        {#each $order.products as product, index}
          <div
            class="row align-items-center text-center text-md-start  shadow m-3 p-2"
          >
            <div class="col-md-auto">
              <img
                style="height: 10rem;"
                class="img-thumbnail"
                src={product.images[0]}
                alt={product.name}
              />
            </div>
            <div class="col-md">
              <h3>{product.name}</h3>
              <div class="row">
                <div class="col">
                  <p class="mb-1">Cena s DPH</p>
                  <h3 class="">{getEuro(product.actionprice || product.price)}</h3>
                </div>
                <div class="col">
                  <p class="mb-1">Počet</p>
                  <h3 class="">{product.quantity}</h3>
                </div>
              </div>
            </div>
            <div class="col-md-auto ">
              <button
                class="btn btn-primary btn-lg"
                on:click={() => removeProduct(index)}
              >
                <i class="bi bi-trash" />
              </button>
            </div>
          </div>
        {/each}
        {/if}
      </div>

    </div>
  </div>
</main>

<style>
  .container {
    max-width: 1680px !important;
  }
  @import "/css/font.css";
  @import "/css/bs-custom.css";
  @import "/css/bootstrap-icons.css";
</style>
