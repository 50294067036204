<script>
const backToTop = () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}
const setActive = (path)=>{
		window.location.replace(path.target.href);
	}

</script>

<main>
	<!-- <div class="container-md mt-5">
		<div class="row justify-content-md-center align-items-center">
			<span class="col"></span>
			<span class="col-8">
				<p class="fs-3">PARTNERI</p>
			</span>
			<span class="col"></span>
		</div>
		<div class="row justify-content-md-center align-items-center w-100" style="margin-bottom: 15rem;">
			<span class="col text-center">
				<button class="btn btn-warning text-white" style="padding: 0; border-top-right-radius:10px; border-bottom-right-radius: 10px; height: 4rem; width: 1.5rem;" >
					<i class="bi bi-caret-right-fill" style="font-size:1.5rem;"></i>
				</button>
				<span class="text-nowrap">
					<img src="/brand/partners/logo_chate.jpg" alt="" srcset="">
					<img src="/brand/partners/laufen.png" alt="" srcset="">
					<img src="/brand/partners/ravak.jpg" alt="" srcset="">
					<img src="/brand/partners/logo-royal.jpg" alt="" srcset="">
					<img src="/brand/partners/logo_stk.gif" alt="" srcset="">
					<img src="/brand/partners/logo_kyokushin.jpg" alt="" srcset="">
					<img src="/brand/partners/laufen.png" alt="" srcset="">
				</span>
				<button class="btn btn-warning text-white" style="padding: 0; border-top-left-radius:10px; border-bottom-left-radius: 10px; height: 4rem; width: 1.5rem;">
					<i class="bi bi-caret-left-fill" style="font-size:1.5rem;"></i>
				</button>
			</span>
		</div>
	</div>
	<div class="row justify-content-md-center align-items-center gx-0" style="height: 13rem;">
		<span class="col text-center bg-secondary">
			<img style="position:relative; top: -150px;" src="/images/venis_nacare.jpg" alt="venis">
		</span>
	</div> -->

	<div class="justify-content-md-center align-items-center bg-secondary pb-5 m-0">
		<div class="container-md">
			<div class="row justify-content-md-center  text-uppercase text-white pt-5">
				<div class="col-md">
					<p class="text-warning fw-normal fs-3" >NÁKUP V E-SHOPE</p>
					<p><a class="text-white" href="/pages/vsetko-o-nakupe/podmienky/obchodne-podmienky" on:click="{setActive}">Obchodné podmienky</a></p>
					<!-- <p><a class="text-white" href="/pages/dopravne-podmienky" on:click="{setActive}">Doprava</a></p> -->
					<p><a class="text-white" href="/pages/vsetko-o-nakupe/podmienky/ochrana-osobnych-udajov" on:click="{setActive}">Ochrana osobných údajov</a></p>
				</div>
				<div class="col-md">
					<p class="text-warning fw-normal fs-3" >BUGÁR, S.R.O.</p>
					<p><a class="text-white" href="/pages/o-nas" on:click={setActive}>O nás</a></p>
					<p><a class="text-white" href="/pages/kontakt" on:click={setActive}>Kde nás nájdete</a></p>
					<p><a class="text-white" href="/pages/vsetko-o-nakupe/podmienky/zasady-pouzivania-suborov-cookies" on:click={setActive}>Zásady používania cookies</a></p>
				</div>
				<div class="col-md">
					<p class="text-warning fw-normal fs-3" >Otváracie hodiny</p>
					<div class="row row-cols-auto">
						<div class="col-ms">
							<p class="fs-5">Sklad</p>
							<p>Po - Pi</p>
							<p>Sobota:</p>
							<p>Nedeľa:</p>
							<p class="fs-5">Predajňa</p>
							<p>Po - Pi</p>
							<p>Sobota:</p>
							<p>Nedeľa:</p>
						</div>
						<div class="col-ms">
							<p class="fs-5">&nbsp;</p>
							<p>07:00 - 18:00</p>
							<p>08:00 - 12:00</p>
							<p>Zatvorené</p>
							<p class="fs-5">&nbsp;</p>
							<p>08:00 - 18:00</p>
							<p>08:00 - 12:00</p>
							<p>Zatvorené</p>
						</div>
					</div>
				</div>
				<div class="col-md">
					<p class="text-warning fw-normal fs-3" >SOCIÁLNE SIETE</p>
					<p>
						<a href="https://www.facebook.com/bugarsro/" class="text-white text-decoration-none align-middle">
								<i class="bi bi-facebook m-1 text-white" style="font-size: 1.5rem;"></i> 
								<span>
									Facebook
								</span>
						</a>
					</p>
					<p>
						<a href="https://www.instagram.com/bugarsro/" class="text-white text-decoration-none align-middle">
								<i class="bi bi-instagram m-1 text-white"  style="font-size: 1.5rem;"></i>
								<span>
									Instagram
								</span>
						</a>
					</p>
					
				</div>
			</div>
			<div  class="text-white text-decoration-none">@2023 BUGÁR, s.r.o.</div>
		</div>
	</div>

</main>

<style>
	@import "/css/font.css";
	@import "/css/bs-custom.css";
	@import "/css/bootstrap-icons.css";
</style>