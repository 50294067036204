<script>
	import { onMount } from "svelte";
    const qrcode = window.location.pathname.split('/vieworder/')[1];
	let order = "";
	let vs = "";
	let state = "";
	onMount(async () => {
    	const resOrder = await fetch(`/order-api/order?qrstring=${qrcode}`);
  		order = await resOrder.json();
		vs = order[0].vs;
		state = order[0].state
		if (state == '') {
			state = "Nová"
		}
		if (state == 'ordered') {
			state = "Objednaná"
		}
		if (state == 'inprogress') {
			state = "Rozpracovaná"
		}
		if (state == 'shipping') {
			state = "Odoslaná"
		}
		if (state == 'closed') {
			state = "Uzatvorená"
		}
  	});

</script>

<main class="text-center align-middle">
	<div class="container w-50">
		<div class="mt-5 mb-5">
			<h2 class="mt-1">Objednávka</h2>
		</div>
		<div class="mt-1 mb-5">
			<div class="row justify-content-center text-start">
				<div class="col-md-4 h5 float-md-end">
					Variabilný symbol 
				</div>
				<div class="col-md-2" >
					{#if vs !== "" }
						{vs}
					{/if}
				</div>
			</div>
			<div class="row justify-content-center text-start">
				<div class="col-md-4 h5 float-md-end">
					Stav objednávky
				</div>
				<div class="col-md-2 ">
					{#if state !== "" }
						{state}
					{/if}
				</div>
			</div>
		</div>

	</div>
</main>

<style>
	@import "/css/font.css";
	@import "/css/bs-custom.css";
</style>
