<script>

</script>
<main>
<div class="container text center">
    <div class="text-center m-5">
        <h1>Stránka nenajdená</h1>
    </div>
</div>
</main>

<style>
    @import "/css/font.css";
	@import "/css/bs-custom.css";
	@import "/css/bootstrap-icons.css";
</style>