<style global> 
        @import "/css/font.css";
        @import "/css/bs-custom.css";
        @import "/css/bootstrap-icons.css";
</style>

<script>
    import router from "page";
    import Header from "./Header.svelte";
    import Footer from "./Footer.svelte";
    import Grid from "./Grid.svelte";
    import DynamicPage from "./DynamicPage.svelte";
    import Product from "./Product.svelte";
    import NotFound from "./NotFound.svelte";
    import Basket from "./Basket.svelte";
    import Signin from "./customer/Signin.svelte";
    import Registration from "./customer/Registration.svelte";
    import Renew from "./customer/Renew.svelte";
    import Deactivation from "./customer/Deactivation.svelte";
    import Information from "./Information.svelte";
    import Shipping from "./Shipping.svelte";
    import Payment from "./Payment.svelte";
    import Home from  "./Home.svelte";
    import Order from  "./Order.svelte";


    import { onMount } from 'svelte';
    import { order } from './storage/orderStore.js';
    import { products } from './storage/productsStore.js';
    import { tages } from './storage/tagesStore.js';
    import NewPassword from "./customer/NewPassword.svelte";
    import Gallery from "./Gallery.svelte";

    
    let page;
    let token;
    let tags = [];
    let params;
    let goto = "/";
    let pathname = window.location.pathname.split('/pages/')[1];
    
    $order
    
    onMount(async () => {
		const productsRes =  await fetch('/products-api/products');
		products.set(await productsRes.json());
		const tagesRes =  await fetch('/tag-api/tags');
        tags = await tagesRes.json()
        tags = await tags.sort((a, b) =>
            Number(a.order) > Number(b.order) ? 1 : -1
        );
		tages.set(tags);
	});

    router("/", () => {
        page = "home";
    });
    router("/eshop*", () => {
        page = "eshop";
        pathname = window.location.pathname.split('/eshop')[1];
    });


    router.redirect("/kategorie/kotle*","/eshop/kotly?filter=KOTLY")
    router.redirect("/kategorie*","/")


    router("/basket", () => {
        page = "basket";
    });
    router("/checkout/information", () => {
        page = "Information";
    });
    router("/checkout/shipping", () => {
        page = "Shipping";
    });
    router("/checkout/payment", () => {
        page = "Payment";
    });
    router("/pages/*", async () => {
        page = "pages";
    });

    router("/product/:product", () => {
        page = "product";
    }); 

    router("/gallery/:id", () => {
        page = "gallery";
    }); 

    router("/admin/:page", () => {
        page = "admin";
        pathname = window.location.pathname.split('/admin/')[1];
    });
    router("/admin", () => {
        page = "admin";
    });

    router("/prihlasenie", () => {
        page = "login";
    });
    router("/registracia", () => {
        page = "registration";
    });
    router("/zabudnute-heslo", () => {
        page = "forgetpassword";
    });
    router("/nove-heslo/:token", () => {
        page = "newpassword";
        token = window.location.pathname.split('/nove-heslo/')[1];
    });
    router("/deaktivacia-uctu", () => {
        page = "dactivation";
    });
    router("/vieworder/:qrcode", () => {
        page = "vieworder";
    });
    router("/404", () => {
        page = NotFound;
    });

    // router("/*", () => {
    //     router.redirect('/pages'+window.location.pathname)
    // });

    router.start();
</script>

<main style="height: 200%;">
    <Header />
    
    {#if page === "home"}
            <Home/>
    {/if}

    {#if page === "eshop"}
        
        <Grid pathname={pathname} />
        
    {/if}

    {#if page === "pages"}
        
        <DynamicPage />
        
    {/if}

    {#if page === "basket"}
        
            <Basket />
        
    {/if}
    {#if page === "Information"}
        
            <Information />
        
    {/if}
    {#if page === "Shipping"}
        
            <Shipping />
        
    {/if}
    {#if page === "Payment"}
        
            <Payment />
        
    {/if}
    {#if page === "admin"}
        <admin-index  pathname={pathname} />
    {/if}

    {#if page === "login"}
        
        <div class="container-md mt-5">
            <div class="row justify-content-md-center align-items-center" >
                <div class="col col-md-4" >
                    <Signin goto={goto}/>
                </div>
            </div>
        </div>
        
    {/if}

    {#if page === "registration"}
    
    <div class="container-md mt-5">
        <div class="row justify-content-md-center align-items-center" >
            <div class="col col-md-4" >
                <Registration />
            </div>
        </div>
    </div>
    
    {/if}

    {#if page === "forgetpassword"}
    
        <div class="container-md mt-5">
            <Renew />
        </div>
        
    {/if}

    {#if page === "newpassword"}
    
        <div class="container-md mt-5">
            <NewPassword token={token}/>
        </div>
        
    {/if}

    {#if page === "dactivation"}
        
        <div class="container-md mt-5">
            <Deactivation />
        </div>
        
    {/if}

    {#if page === "product"}
        
            <Product/>
        
    {/if}

    {#if page === "gallery"}
            <Gallery/>
    {/if}

    {#if page === NotFound}
    
        <NotFound />
    
    {/if}

    {#if page === "vieworder"}
    
        <Order/>
    
    {/if}
    <!-- <pre>
        {#each $product as product }
            {product.name}
        {/each}
    </pre> -->
    <!-- <pre>
        {#each $tages as tag }
            {tag.name}
        {/each}
    </pre> -->
    <Footer />
</main>

